import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth, db } from "../firebase";
import { doc, updateDoc, getDoc, setDoc } from "firebase/firestore";
import { Library } from "../data";
import Question from "../components/question";

export const Questions = () => {
  const { t } = useTranslation();
  const { quesId, year } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const quest = Library().questions;
  const [warn, setWarn] = useState("");
  const newWarn = (value) => {
    setWarn(value);
    setTimeout(() => {
      setWarn("");
    }, 3000);
  };

  const [index, setIndex] = useState(0);

  const [gross, setGross] = useState(0);
  const [recRent, setRecRent] = useState(["", ""]);
  const [recRentType, setRecRentType] = useState([""]);
  const [tax, setTax] = useState([""]);
  const [interest, setInterest] = useState(["", "", "", "", ""]);
  const [$80c, set$80c] = useState(["", "", "", ""]);
  const [nps, setNps] = useState(["", ""]);
  const [$80d, set$80d] = useState(["", ""]);
  const [$80dd, set$80dd] = useState("");
  const [$80ddb, set$80ddb] = useState(["", ""]);
  const [$80ddbRelation, set$80ddbRelation] = useState("");
  const [$80g, set$80g] = useState([""]);
  const [$80tta, set$80tta] = useState([""]);
  const [$80u, set$80u] = useState([""]);
  const [$80gg, set$80gg] = useState([""]);
  const [$16b, set$16b] = useState([""]);
  const [home, setHome] = useState([""]);

  const [costTourAllow, setCostTourAllow] = useState([""]);
  const [childEduAllow, setChildEduAllow] = useState([""]);
  const [rentAllow, setRentAllow] = useState([""]);
  const [travelAllow, setTravelAllow] = useState([""]);
  const [otherAllow, setOtherAllow] = useState([""]);

  // const covertToNumber = (arr = []) => {
  //   let newArr = [];
  //   arr.forEach((item, index) => {
  //     newArr[index] = item.toString().split(",").join("");
  //   });
  //   return newArr;
  // };
  const convertToNumber = (value) => {
    // Check if the value is already a number
    if (typeof value === "number") {
      console.log("convert is number: ", value);
      return value;
    }

    // If it's an array, recursively convert each element
    if (Array.isArray(value)) {
      console.log("convert is array: ", value);
      // return covertToNumber(value[0]);
      return value.map(convertToNumber);
    }

    // If it's a string, trim it, remove commas, and convert to a number
    if (typeof value === "string") {
      const trimmedValue = value.trim().replace(/,/g, ""); // Remove commas
      const numberValue = Number(trimmedValue);
      console.log("Convert String: ", numberValue);
      
      // Check if conversion resulted in NaN
      if (isNaN(numberValue)) {
        console.error(`Invalid number: ${trimmedValue}`);
        return 0; // or handle it as needed (e.g., throw an error)
      }
      return convertToNumber(numberValue);
    }

    // Handle other cases as needed (e.g., objects)
    console.error(`Unsupported type: ${typeof value}`);
    return 0; // or handle it as needed
  };
  const currentAY = process.env.REACT_APP_CURRENT_AY;
  let dbSavingInterest = 0;
  const fetchData = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      // const currentYear = year ? year : process.env.REACT_APP_CURRENT_YEAR;
      const currentYear = process.env.REACT_APP_CURRENT_AY;
      // const currentYear = year;
      const match = user.email.match(/\+(.*?)@/);
      const pan = match[1].toUpperCase();
      // const docRef = doc(db, currentYear.toString(), pan);
      const docRef = doc(db, "itrWorking", pan);
      const docSnap = await getDoc(docRef);
      // console.log(docSnap.data());

      if (docSnap.exists()) {
        // const data = docSnap.data().question || {};
        // setGross(data.gross || [""]);
        // setRecRentType(data.recRentType || "", "");
        // setTax(data.tax || [""]);
        // setInterest(data.interest || ["", "", "", "", ""]);
        // set$80c(data.$80c || ["", "", "", ""]);
        // setNps(data.nps || [""]);
        // set$80d(data.$80d || ["", ""]);
        // set$80dd(data.$80dd || "");
        // set$80ddb(data.$80ddb || ["", ""]);
        // set$80ddbRelation(data.$80ddbRelation || "");
        // set$80g(data.$80g || [""]);
        // set$80tta(data.$80tta || [""]);
        // set$80u(data.$80u || ["", ""]);
        // set$80gg(data.$80gg || [""]);
        // set$16b(data.$16b || [""]);
        // setHome(data.home || [""]);
        // setCostTourAllow(data.costTourAllow || [""]);
        // setChildEduAllow(data.childEduAllow || [""]);
        // setRentAllow(data.rentAllow || [""]);
        // setTravelAllow(data.travelAllow || [""]);
        // setOtherAllow(data.otherAllow || [""]);

        const qdata = docSnap.data();
        console.log("new db:", qdata);
        // const data = qdata[currentYear];
        const data = qdata[year];
        console.log("new DB Year data", data);
        // setGross(data?.incomeFromSalaries[0]?.salary);
        const sal = data?.incomeFromSalaries[0]?.salary;
        console.log("sal:", sal);
        const safeGross = Array.isArray(sal) ? sal : [sal];
        setGross(safeGross);
        const letOutGross = data.incomeFromHouseProperty?.LetOut.grossAnnual || 0;
        console.log("letoutgross:", letOutGross);
        const letOutMunicipal = data.incomeFromHouseProperty?.LetOut.municipalTaxes || 0;
        console.log("letoutMunicipal:", letOutMunicipal);
        const selfOccupiedInterest = data.incomeFromHouseProperty?.SelfOccupied.interestOnBorrowedCapital || 0;
        console.log("selfoccupiedInterest:", selfOccupiedInterest);
        setRecRent([letOutGross, letOutMunicipal]);
        if(letOutGross) {
          setRecRentType(["Let Out"]);
        } else if(selfOccupiedInterest) {
          setRecRentType(["Self Occupied"]);
        } else {
          setRecRentType(["Let Out"]);
        }
        setInterest([
          data.incomeFromOtherSources.interestIncome?.InterestOnSavingsAccount || 0,
          data.incomeFromOtherSources.interestIncome?.InterestOnTimeDeposits || 0,
          0,
          data.incomeFromOtherSources.dividends || 0,
          data.incomeFromOtherSources.interestIncome?.otherInterest || 0
        ]);
        // console.log("80CCD db data: ", data.deductions["80CCD"]["80CCD-2"]);
        set$80c([
          data.deductions["80C"].lic || 0,
          data.deductions["80C"].homeLoan || 0,
          data.deductions["80C"].gpf || 0,
          data.deductions["80C"].tuitionFee || 0,
        ]);
        setNps([data.deductions["80CCD"]["80CCD-1B"] || 0, data.deductions["80CCD"]["80CCD-2"] || 0]);
        set$80d([
          data.deductions["80D"].below60 || 0,
          data.deductions["80D"].above60 || 0,
        ]);
        const db80dd = data.deductions["80DD"] || 0;
        if (db80dd == 75000) {
          set$80dd("Less than 80%");
        } else if (db80dd == 125000) {
          set$80dd("More than 80%");
        } else {
          set$80dd("No disability");
        }
        set$80ddbRelation(data.deductions["80DDB"].userType);
        if ($80ddbRelation == "Self or Dependent") {
          set$80ddb([data.deductions["80DDB"].amount || 0, 0]);
        } else {
          set$80ddb([0, data.deductions["80DDB"].amount || 0]);
        }
        set$80g([data.deductions["80G"] || 0]);
        set$80gg([data.deductions["80GG"] || 0]);
        setCostTourAllow([data.incomeFromSalaries[0].allowance.uniform || 0]);
        setChildEduAllow([data.incomeFromSalaries[0].allowance.edu || 0]);
        setRentAllow([data.incomeFromSalaries[0].allowance.hra || 0]);
        const ddb80u = data.deductions["80U"] || 0;
        if (ddb80u == 75000) {
          set$80u("Self with Disability");
        } else if (ddb80u == 125000) {
          set$80u("Self with Severe Disability");
        } else {
          set$80u("No disability");
        }
        // dbSavingInterest = data.incomeFromOtherSources.Interestincome.InterestOnSavingsAccount || 0;
        set$80tta([data.deductions["80TTA"] || 0]);

      }
    } catch (error) {
      newWarn("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [year]);

  // const submitForm = async (
  //   field1,
  //   field2,
  //   index1 = +quesId,
  //   field3 = "",
  //   field4 = [],
  //   index2 = null
  // ) => {
  //   setLoading(true);
  //   const user = auth.currentUser;
  //   const currentYear = year ? year : process.env.REACT_APP_CURRENT_YEAR;
  //   const match = user.email.match(/\+(.*?)@/);
  //   const pan = match[1].toUpperCase();
  //   const docRef = doc(db, currentYear.toString(), pan);
  //   try {
  //     if (!field3) {
  //       await updateDoc(
  //         docRef,
  //         {
  //           [`question.${field1}`]: covertToNumber(field2),
  //           [`question.suggestions.${index1}`]: false,
  //         },
  //         { merge: true }
  //       );
  //     } else {
  //       await updateDoc(
  //         docRef,
  //         {
  //           [`question.${field1}`]: covertToNumber(field2),
  //           [`question.${field3}`]: covertToNumber(field4),
  //           [`question.suggestions.${index1}`]: false,
  //           [`question.suggestions.${index2}`]: false,
  //         },
  //         { merge: true }
  //       );
  //     }
  //     setLoading(false);
  //     navigate("/dashboard", { replace: true });
  //   } catch (error) {
  //     setLoading(false);
  //     newWarn("Something went wrong, please try again");
  //     console.error("Error updating document: ", error);
  //   }
  // };
  const submitForm = async (
    field1,
    field2,
    index1 = +quesId,
    field3 = "",
    field4 = [],
    index2 = null
  ) => {
    setLoading(true);
    const user = auth.currentUser ;
    const currentYear = year ? year : process.env.REACT_APP_CURRENT_YEAR; // Ensure this reflects the new structure
    const currentAY = process.env.REACT_APP_CURRENT_AY;
    const match = user.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const docRef = doc(db, "itrWorking", pan); // Ensure the collection path is correct
    const docSnap = await getDoc(docRef);

    try {
      // Create an object to hold the updates
      const updates = {};
      const subdata = docSnap.data();
      const subyeardata = subdata[currentAY];
      console.log("Submit Fetch DB Data: ", subyeardata);

      // Determine where to place the data based on the field1 parameter
      if (field1 == "gross") {
        console.log("Field2 value before conversion:", field2);
        const salaryValue = convertToNumber(field2);
        console.log("Converted salary value:", salaryValue);
        updates[`${currentYear}.incomeFromSalaries.0.salary`] = salaryValue[0];
        // updates[`"${currentYear}".incomeFromSalaries`] = [
        //   {
        //     salary: salaryValue[0]
        //   }
        // ];
        // const itrWorkingUpdate = {
        //   [currentYear]: {
        //     incomeFromSalaries: [
        //       {
        //         salary: salaryValue[0], // Update only the salary field
        //       }
        //     ]
        //   }
        // };
        console.log("inside gross");
        // await setDoc(docRef, itrWorkingUpdate, { merge: true });
        // await updateDoc(docRef, itrWorkingUpdate, { merge: true });
      } else if (field1 == "recRent") {
        console.log("inside recRent");
        console.log("field2", field2);
        console.log("field3", field3);
        console.log("field4", field4);
        const rentValue = convertToNumber(field2);
        const munValue = convertToNumber(field3);
        if(field4 == "Self Occupied") {
          console.log("inside selfOccupied");
          updates[`${currentYear}.incomeFromHouseProperty.SelfOccupied`] = [
            {
              interestOnBorrowedCapital: rentValue[0],
            }
          ];
        } else if (field4 == "Let Out") {
          updates[`${currentYear}.incomeFromHouseProperty.LetOut`] = [
            {
              grossAnnual: rentValue[0],
              municipalTaxes: munValue[0],
            }
          ];
        }
      } else if (field1 == "interest") {
        console.log("inside Interest");
        console.log("Interest: ", field2);
        const intSaveValue = convertToNumber(field2[0]);
        const safeIntSaveValue = intSaveValue > 10000 ? 10000 : intSaveValue;
        const intTimeDepValue = convertToNumber(field2[1]);
        // const intRDValue = convertToNumber(field2[2]);
        const intDivValue = convertToNumber(field2[3]);
        const intotherValue = convertToNumber(field2[4]);
        updates[`${currentYear}.incomeFromOtherSources.divedends`] = intDivValue;
        updates[`${currentYear}.incomeFromOtherSources.interestIncome`] = {
          InterestOnSavingsAccount : safeIntSaveValue,
          InterestOnTimeDeposits : intTimeDepValue,
          otherInterest : intotherValue,
        };
        updates[`${currentYear}.deductions.80TTA`] = safeIntSaveValue;
      } else if (field1 == "$80c") {
        updates[`${currentYear}.deductions.80C`] = {
          "tuitionFee": convertToNumber(field2)[3],
          "lic": convertToNumber(field2)[0],
          "homeLoan": convertToNumber(field2)[1],
          "gpf": convertToNumber(field2)[2],
        };
      } else if (field1 == "nps") {
        console.log("Inside NPS");
        updates[`${currentYear}.deductions.80CCD`] = {
          "80CCD-1": 0,
          "80CCD-1B": 0,
          "80CCD-2": convertToNumber(field2)[0],
        };
      } else if (field1 == "$80d") {
        updates[`${currentYear}.deductions.80D`] = {
          "below60": convertToNumber(field2)[0],
          "above60": convertToNumber(field2)[1],
        };
      } else if (field1 == "$80dd") {
        let disabilityTotal = 0;
        if (field2 == 1) {
          disabilityTotal = 75000;
        } else if (field2 == 2) {
          disabilityTotal = 125000;
        }
        updates[`${currentYear}.deductions.80DD`] = disabilityTotal;
      } else if (field1 == "$80ddb") {
        let below60 = convertToNumber(field2[0]);
        let above60 = convertToNumber(field2[1]);
        let relation80ddb = field3[0];
        console.log("relation: ", relation80ddb);
        if (relation80ddb == "Self or Dependent") {
          if (below60 > 40000) {
            updates[`${currentYear}.deductions.80DDB`] = {
              "userType": relation80ddb,
              "amount": 40000,
            };
          } else {
            updates[`${currentYear}.deductions.80DDB`] = {
              "userType": relation80ddb,
              "amount": below60,
            };
          }
        } else {
          if (above60 > 100000) {
            updates[`${currentYear}.deductions.80DDB`] = {
              "userType": relation80ddb,
              "amount": 100000,
            };
          } else {
            updates[`${currentYear}.deductions.80DDB`] = {
              "userType": relation80ddb,
              "amount": above60,
            };
          }
        }
      } else if (field1 == "$80g") {
        updates[`${currentYear}.deductions.80G`] = convertToNumber(field2)[0];
      } else if (field1 == "$80gg") {
        updates[`${currentYear}.deductions.80GG`] = convertToNumber(field2)[0];
      } else if (field1 == "costTourAllow") {
        updates[`${currentYear}.incomeFromSalaries.0.allowance.uniform`] = convertToNumber(field2[0]);
      } else if (field1 == "childEduAllow") {
        updates[`${currentYear}.incomeFromSalaries.0.allowance.edu`] = convertToNumber(field2[0]);
      } else if (field1 == "rentAllow") {
        updates[`${currentYear}.incomeFromSalaries.0.allowance.hra`] = convertToNumber(field2[0]);
      } else if (field1 == "$80u") {
        // let inNotSevere = convertToNumber(field2[0]);
        // let inSevere = convertToNumber(field2[1]);
        let disability80u = 0;
        if (field2 == 1) {
          disability80u = 75000;
        } else if (field2 == 2) {
          disability80u = 125000;
        }
        updates[`${currentYear}.deductions.80U`] = disability80u;
        console.log("submited 80U");
        // if (inNotSevere >= 0 && inNotSevere > inSevere && inSevere >= 0) {
        //   updates[`${currentYear}.deductions.80U`] = {
        //     "disability": inNotSevere,
        //     "severeDisability": 0,
        //   };
        // } else if (inSevere >= 0 && inSevere > inNotSevere && inNotSevere >= 0) {
        //   updates[`${currentYear}.deductions.80U`] = {
        //     "disability": 0,
        //     "severeDisability": inSevere,
        //   };
        // }
      } else if (field1 == "$80tta") {
        const fetDBIntSavings = subyeardata.incomeFromOtherSources.interestIncome.InterestOnSavingsAccount;
        const resIntSave = convertToNumber(field2[0]);
        // console.log("cdkkdsndndsnc");
        if (resIntSave == fetDBIntSavings || resIntSave <= fetDBIntSavings || resIntSave < 10000) {
          updates[`${currentYear}.deductions.80TTA`] = resIntSave;
          updates[`${currentYear}.incomeFromOtherSources.interestIncome.InterestOnSavingsAccount`] = resIntSave;
        } else if (resIntSave > 10000) {
          updates[`${currentYear}.deductions.80TTA`] = 10000;
          updates[`${currentYear}.incomeFromOtherSources.interestIncome.InterestOnSavingsAccount`] = 10000;
        }
      }
      // Add more conditions for other fields based on the new structure...

      // Handle suggestions
      // if (index1 !== null) {
      //   updates[`question.suggestions.${index1}`] = false;
      // }
      // if (index2 !== null) {
      //   updates[`question.suggestions.${index2}`] = false;
      // }

      // Update the document in Firestore
      // await updateDoc(docRef, updates, { merge: true });
      await updateDoc(docRef, updates);
      // await setDoc(docRef, itrWorkingUpdate, { merge: true });
      
      setLoading(false);
      // navigate("/dashboard", { replace: true });
    } catch (error) {
      setLoading(false);
      newWarn("Something went wrong, please try again");
      console.error("Error updating document: ", error);
    }
  };
  return (
    <>
      {/* // warning message */}
      {warn && (
        <div className="alert alert-danger quest-alert" style={{ margin: 0 }}>
          {warn}
        </div>
      )}
      {
        //question 0
        (quesId === "nbsp" && index === 0 && (
          <Question
            showUpload={true}
            isNBSP={true}
            questID={0}
            question={quest[0]}
            state={[gross, setGross]}
            label={["Enter Gross Annual Income"]}
            suggestion={false}
            onAnswer={() => {
              if (gross[0] !== "" && Number(gross[0].split(",").join("")) > 0) {
                setIndex(index + 1);
              } else {
                newWarn("Please enter your correct gross income");
              }
            }}
          />
        )) ||
        (quesId === "nbsp" && index === 1 && (
          <Question
            showUpload={true}
            isNBSP={true}
            questID={4}
            question={quest[4]}
            state={[$80c, set$80c]}
            label={[
              "Premium for LIC",
              "Premium for Home Loan",
              "Premium for GPF",
              "Annual Tution Fees",
            ]}
            onSubmit={() => {
              // cheack if vlaues are positive
              if (
                (Number($80c[0].split(",").join("")) >= 0 ||
                  $80c[0] === "") &&
                (Number($80c[1].split(",").join("")) >= 0 ||
                  $80c[1] === "") &&
                (Number($80c[2].split(",").join("")) >= 0 ||
                  $80c[2] === "") &&
                (Number($80c[3].split(",").join("")) >= 0 || $80c[3] === "")
              ) {
                submitForm("gross", gross, 0, "$80c", $80c, 4);
              } else {
                newWarn("Value can't be negative.");
              }
            }}
            onPrev={() => {
              setIndex(index - 1);
            }}
          />
        ))
      }
      {+quesId === 0 && (
        <Question
          showUpload={true}
          questID={quesId}
          question={quest[0]}
          state={[gross, setGross]}
          label={["Enter Gross Annual Income"]}
          suggestion={false}
          onSubmit={() => {
            if (gross[0] !== "" && Number(gross[0].split(",").join("")) > 0) {
              submitForm("gross", gross);
            } else {
              newWarn("Please enter your correct gross income");
            }
          }}
        />
      )}
      {
        // question 2
        +quesId === 1 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[recRent, setRecRent]}
            dropdown={{
              label: "Type",
              options: ["Self Occupied", "Let Out"],
              value: recRentType,
              onChange: (value) => setRecRentType(value),
            }}
            label={["Enter MONTHLY Income from Rent", "Enter Annually Property Tax Paid"]}


            onSubmit={() => {
              // Get the numeric value of the entered rent
              const rentValue = Number(recRent[0].split(",").join(""));

              // Get the numeric value of the entered property tax
              const propertyTaxValue = Number(recRent[1].split(",").join(""));

              // Determine whether the rent should be positive or negative based on the dropdown value
              // const adjustedRent = recRentType === "Self Occupied" ? -Math.abs(rentValue) : Math.abs(rentValue);

              // Calculate the difference between property tax and adjusted rent
              const amountA = rentValue * 12;

              // Calculate 30% of amountA
              // const amountB = amountA * 0.3;

              // Calculate the final amount (amountC)
              // const amountC = amountA - amountB;

              // Store the final amount (amountC) in the form
              submitForm("recRent", [amountA.toString()], "", [propertyTaxValue.toString()], recRentType);
            }}
          />
        )

      }
      {
        // question 3
        +quesId === 2 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[tax, setTax]}
            label={["Enter Municipal tax paid"]}
            onSubmit={() => {
              if (Number(tax[0].split(",").join("")) >= 0 || tax[0] === "") {
                submitForm("tax", tax);
              } else newWarn("Value cant be negative.");
            }}
          />
        )
      }
      {
        // question 4
        +quesId === 3 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[interest, setInterest]}
            label={[
              t("Interest on Savings Account"),
              t("Interest on Time Deposit"),
              t("Interest from RD"),
              t("Interest from Dividend"),
              t("Interest from other Investments"),
            ]}
            // placeholder={[
            //   "Enter Bank Interest",
            //   "Enter Interest from RD",
            //   "Enter Interest from FD",
            //   "Enter Interest from Dividend",
            //   "Enter Interest from other Investments",
            // ]}
            onSubmit={() => {
              if (
                Number(interest[0].split(",").join("")) >= 0 ||
                interest[0] === ""
              ) {
                submitForm("interest", interest);
              } else {
                newWarn("Value cant be negative.");
              }
            }}
          />
        )
      }
      {
        // question 1
        +quesId === 4 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[$80c, set$80c]}
            label={[
              "Premium for LIC",
              "Premium for Home Loan",
              "Premium for GPF",
              "Annual Tution Fees",
            ]}
            placeholder={[
              "Enter Premium for LIC",
              "Enter Premium for Home Loan",
              "Enter Premium for GPF",
              "Enter Annual Tution Fees",
            ]}
            onSubmit={() => {
              // cheack if vlaues are positive
              if (
                (Number($80c[0]) >= 0 || $80c[0] === "") &&
                (Number($80c[1]) >= 0 || $80c[1] === "") &&
                (Number($80c[2]) >= 0 || $80c[2] === "") &&
                (Number($80c[3]) >= 0 || $80c[3] === "")
              ) {
                submitForm("$80c", $80c);
              } else {
                newWarn("Value cant be negative.");
              }
            }}
          />
        )
      }
      {
        // question 5
        +quesId === 5 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[nps, setNps]}
            label={["Enter Amount paid for NPS", "CCD2"]}
            placeholder={["CCD1-B", "CCD-2"]}
            onSubmit={() => {
              if (
                (Number(nps[0].split(",").join("")) >= 0 || nps[0] === "") && 
                (Number(nps[1].split(",").join("")) >= 0 || nps[1] === "") 
              ) {
                submitForm("nps", nps);
              } else {
                newWarn("Value cant be negative.");
              }
            }}
          />
        )
      }
      {
        // question 6
        +quesId === 6 && (
          <Question
            showUpload={true}
            questID={quesId}
            question={quest[+quesId]}
            state={[$80d, set$80d]}
            label={["for below 60 years", "for above 60 years"]}
            onSubmit={() => {
              // cheack if vlaues are positive
              if (
                (Number($80d[0].split(",").join("")) >= 0 || $80d[0] === "") &&
                (Number($80d[1].split(",").join("")) >= 0 || $80d[1] === "")
              ) {
                submitForm("$80d", $80d);
              } else {
                newWarn("Value cant be negative.");
              }
            }}
          />
        )
      }
      {+quesId === 7 && (
        <Question
          questID={quesId}
          question={quest[+quesId]}
          radio={true}
          state={[$80dd, set$80dd]}
          options={["No disability", "Less than 80%", "More than 80%"]}
          onSubmit={() => {
            if ($80dd[0] >= 0 || $80dd[0] === "") {
              console.log("$80DD: ", $80dd[0]);
              submitForm("$80dd", $80dd);
            } else {
              newWarn("Value cant be negative.");
            }
          }}
        />
      )}
      {+quesId === 8 && (
        <Question
          showUpload={true}
          questID={quesId}
          question={quest[+quesId]}
          state={[$80ddb, set$80ddb, $80ddbRelation, set$80ddbRelation]}
          dropdown={{
            label: "Relation",
            options: ["Self or Dependent", "Self or Dependent - Senior Citizen"],
            value: $80ddbRelation,
            onChange: (value) => set$80ddbRelation(value),
          }}
          label={["for below 60 years", "for above 60 years"]}
          onSubmit={() => {
            // Check if values are positive
            if (
              (Number($80ddb[0].split(",").join("")) >= 0 || $80ddb[0] === "") &&
              (Number($80ddb[1].split(",").join("")) >= 0 || $80ddb[1] === "") &&
              ($80ddbRelation === "Self or Dependent" || $80ddbRelation === "Self or Dependent - Senior Citizen")
            ) {
              submitForm("$80ddb", $80ddb, "$80ddbRelation", [$80ddbRelation]);
            } else {
              newWarn("Value can't be negative or relation is missing.");
            }
          }}
          onPrev={() => {
            setIndex(index - 1);
          }}
        />
      )}
      {+quesId === 9 && (
        <Question
          showUpload={true}
          questID={quesId}
          question={quest[+quesId]}
          state={[$80g, set$80g]}
          label={["Enter the amount Donated"]}
          onSubmit={() => {
            if (Number($80g[0].split(",").join("")) >= 0 || $80g[0] === "") {
              submitForm("$80g", $80g);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 10 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Enter the rent paid ANNUALLY"]}
          state={[$80gg, set$80gg]}
          onSubmit={() => {
            if (Number($80gg[0].split(",").join("")) >= 0 || $80gg[0] === "") {
              submitForm("$80gg", $80gg);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}

      {+quesId === 11 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Income from other employer(s)"]}
          state={[$16b, set$16b]}
          onSubmit={() => {
            if (Number($16b[0].split(",").join("")) >= 0 || $16b[0] === "") {
              submitForm("$16b", $16b);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 12 && (
        <Question
          //showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Cost of travel on tour or transfer, maintainence of uniform"]}
          state={[costTourAllow, setCostTourAllow]}
          onSubmit={() => {
            if (Number(costTourAllow[0].split(",").join("")) >= 0 || costTourAllow[0] === "") {
              submitForm("costTourAllow", costTourAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 13 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Children Education allowance, Hostel Allowance"]}
          state={[childEduAllow, setChildEduAllow]}
          onSubmit={() => {
            if (Number(childEduAllow[0].split(",").join("")) >= 0 || childEduAllow[0] === "") {
              submitForm("childEduAllow", childEduAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 14 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["House Rent Allowance"]}
          state={[rentAllow, setRentAllow]}
          onSubmit={() => {
            if (Number(rentAllow[0].split(",").join("")) >= 0 || rentAllow[0] === "") {
              submitForm("rentAllow", rentAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 18 && (
        <Question
          // showUpload={true}
          questID={quesId}
          question={quest[+quesId]}
          radio={true}
          state={[$80u, set$80u]}
          // label={["Self with Disability", "Self with severe disability"]}
          options={["No disability", "Self with Disability", "Selg with Severe Disability"]}
          onSubmit={() => {
            // cheack if vlaues are positive
            if ($80u[0] >= 0 || $80u[0] === "") {
              console.log("$80U: ", $80u[0]);
              submitForm("$80u", $80u);
            } else {
              newWarn("Value cant be negative.");
            }
          }}
        />
      )}
      {+quesId === 19 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Enter your interest amount"]}
          state={[$80tta, set$80tta]}
          onSubmit={() => {
            if (Number($80tta[0].split(",").join("")) >= 0 || $80tta[0] === "") {
              submitForm("$80tta", $80tta);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 15 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Travel Allowance"]}
          state={[travelAllow, setTravelAllow]}
          onSubmit={() => {
            if (Number(travelAllow[0].split(",").join("")) >= 0 || travelAllow[0] === "") {
              submitForm("travelAllow", travelAllow);
            } else newWarn("Value can't be negative.");
          }}
        />
      )}
      {+quesId === 16 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Other Allowances"]}
          state={[otherAllow, setOtherAllow]}
          onSubmit={() => {
            if (Number(otherAllow[0].split(",").join("")) >= 0 || otherAllow[0] === "") {
              submitForm("otherAllow", otherAllow);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
      {+quesId === 17 && (
        <Question
          showUpload={true}
          questID={quesId}
          loading={isLoading}
          question={quest[+quesId]}
          label={["Enter the interest on Saving Account"]}
          state={[$80tta, set$80tta]}
          onSubmit={() => {
            if (Number($80tta[0].split(",").join("")) >= 0 || $80tta[0] === "") {
              submitForm("$80tta", $80tta);
            } else newWarn("Value cant be negative.");
          }}
        />
      )}
    </>
  );
};
