// import UserCard from "../components/user-card";
import { React, useEffect, useRef, useState, useLayoutEffect } from "react";
import {
  analyseRes,
  TotalTaxableIncome,
  TotalTaxPaid,
  modifyPdf,
  useUserData,
  useUser,
  useUserTax,
} from "../data";
import PartApdf from "../data/Pdf";
import logo from "../assets/images/logo.png";
import "./otheremp.css";
import { doc, getDoc, updateDoc, setDoc, arrayUnion } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useTranslation } from "react-i18next";
import Status from "./Status";

import {
  ListItem,
  YearSelector,
  CardField,
  ShowAllButton,
} from "../components";

// ShowAllButton,
const Dashboard = (userPAN = { userPAN }) => {
  const { t } = useTranslation();
  const prevYearRef = useRef();
  const currentYear = new Date().getFullYear();
  const currentAY = `${currentYear}-${currentYear + 1}`;
  // const currentAY = process.env.REACT_APP_CURRENT_AY;
  console.log("current AY :", currentAY);
  

  const [loading] = useState(false);
  const [year, setYear] = useState(currentAY);
  // const [year, setYear] = useState(currentAY);
  const [userData, setUserData] = useState(null);
  const [Data, setData] = useState(null);
  const [notice, setNotice] = useState("No notice from admin");
  const [otherEmp, setotherEmp] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedRegime, setSelectedRegime] = useState("");

  const handleStatusChange = (event) => {
    setSelectedRegime(event.target.value);
  };

  // -----------------------------------------------------------------------

  const analyseResponse = (res) => {
    console.log("response of deductions is ", res);
    if (res) {
      // const calc24 = () => {
      //   return (res?.recRent[0] * 12 - res?.tax[0]) * 0.3;
      // };
      const calc80C = () => {
        let total = 0;
        let d80Cgpf = res?.deductions?.["80C"]?.gpf || 0;
        let d80Chomeloan = res?.deductions?.["80C"]?.homeLoan || 0;
        let d80Clic = res?.deductions?.["80C"]?.lic || 0;
        let d80Ctuition = res?.deductions?.["80C"]?.tuitionFee || 0;
        total = total + d80Cgpf + d80Chomeloan + d80Clic + d80Ctuition;
        console.log("80C: ", total);
        return total < 150000 ? total : 150000;
      };
      const calc80CCD = () => {
        let total = 0;
        let d80CCD1 = res?.deductions?.["80CCD"]?.["80CCD-1"] || 0;
        let d80CCD1B = res?.deductions?.["80CCD"]?.["80CCD-1B"] || 0;
        let d80CCD2 = res?.deductions?.["80CCD"]?.["80CCD-2"] || 0;
        total = d80CCD1B + d80CCD2;
        console.log("80CCD", total);
        return total < 50000 ? total : 50000;
      };
      const calc80D = () => {
        let total = 0;
        let d80Dabove60 = res?.deductions?.["80D"]?.above60 || 0;
        let d80Dbelow60 = res?.deductions?.["80D"]?.below60 || 0;
        if (d80Dabove60) {
          total += d80Dabove60 < 25000 ? + d80Dabove60 : 25000;
        }
        if (d80Dbelow60) {
          total += d80Dbelow60 < 50000 ? + d80Dbelow60 : 50000;
        }
        console.log("80D: ", total);
        console.log("80DD: ", Number(res?.deductions?.["80DD"] || 0));
        return total;
      };
      const calc80DDB = () => {
        let total = 0;
        let d80DDBamount = res?.deductions?.["80DDB"]?.amount || 0;
        total = d80DDBamount;
        // let d80DDBabove = res?.deductions?.["80DDB"]?.above60 || 0;
        // let d80DDBbelow = res?.deductions?.["80DDB"]?.below60 || 0;
        // if (d80DDBabove) {
        //   total = d80DDBabove < 100000 ? d80DDBabove : 100000;
        // } else if (d80DDBbelow) {
        //   total = d80DDBbelow < 40000 ? d80DDBbelow : 40000;
        // }
        console.log("80DDB: " + total);
        return total;
      };
      const calc80GG = () => {
        let total = 0;
        let d80GG = res?.deductions?.["80GG"] || 0;
        total = d80GG < 60000 ? d80GG : 60000;
        return total;
      };
      // const calc80TTA = () => {
      //   return +res?.$80tta[0] < 10000 ? +res?.$80tta[0] : 10000;
      // };
      const calcInterest = () => {
        let total = 0;
        let intDiv = res?.incomeFromOtherSources?.dividends;
        let intSaveAC = res?.incomeFromOtherSources?.interestIncome?.InterestOnSavingsAccount;
        let intTimeDep = res?.incomeFromOtherSources?.interestIncome?.InterestOnTimeDeposits;
        let intTime = res?.incomeFromOtherSources?.interestIncome?.otherInterest;
        total = intDiv + intSaveAC + intTimeDep + intTime;
        console.log("Interest Income: ", total);
        return total;
      };
      const calcIncome = () => {
        let total = 0;
        // let resLength = res?.incomeFromSalaries.length;
        // for (let i = 0; i < resLength; i++) {
        //   total += res?.incomeFromSalaries?.[i]?.salary;
        // }
        total = res?.incomeFromSalaries?.[0]?.salary || 0;
        console.log("Gross: ", total);
        return total;
      };
      const calcIncomeOtherEmp = () => {
        let total = 0;
        let resLength = res?.incomeFromSalaries.length;
        const othemp = Object.values(res?.incomeFromSalaries);
        for (let i = 1; i < othemp.length; i++) {
          total += othemp[i]?.salary;
        }
        // total = Object.values(res?.incomeFromSalaries).slice(1).reduce((acc, salary) => acc + salary.salary, 0);
        console.log("Income Other Employee: ", total);
        return total;
      };
      const calcRent = () => {
        let total = 0;
        let rentLet = res?.incomeFromHouseProperty?.LetOut?.grossAnnual;
        let rentSelf = res?.incomeFromHouseProperty?.SelfOccupied?.interestOnBorrowedCapital;
        if (rentLet) {
          let rentMunicipal = res?.incomeFromHouseProperty?.LetOut?.municipalTaxes;
          let rentOneNet = rentLet - rentMunicipal;
          let rentNet = rentOneNet - (rentOneNet*0.30);
          let rentInt = res?.incomeFromHouseProperty?.LetOut?.interestOnBorrowedCapital;
          total = rentNet - rentInt;
        } else if (rentSelf) {
          let selfInt = res?.incomeFromHouseProperty?.SelfOccupied?.interestOnBorrowedCapital || 0;
          let safeSelfInt = selfInt > 200000 ? 200000 : selfInt;
          total = 0 - safeSelfInt;
        }
  
        console.log("IncomeFromHouseProperty: ", total);
        return total;
      };
      const calc80U = () => {
        let total = 0;
        let d80U = res?.deductions?.["80U"] || 0;
        // let d80Udis = res?.deductions?.["80U"]?.disability || 0;
        // let d80Usevdis = res?.deductions?.["80U"]?.severeDisability || 0;
        // if(d80Udis) {
        //   total = 75000;
        // } else if(d80Usevdis) {
        //   total = 125000;
        // } else {
        //   total = 0;
        // }
        total = d80U;
        console.log("80U: ", total);
        return total;
      };
      const calc80TTA = () => {
        let total = 0;
        let d80TTA = res?.deductions?.["80TTA"] || 0;
        let dintSaveAC = res?.incomeFromOtherSources?.interestIncome?.InterestOnSavingsAccount;
        if (dintSaveAC) {
          total = Number(d80TTA < 10000 ? d80TTA : 10000);
        } else {
          total = 0;
        }
        console.log("80TTA: ", total);
        return total;
      };
  
      const data = {
        incomeData: [
          // {
          //   valid: res?.gross[0] ? true : false,
          //   name: "Total Salary",
          //   amt: Number(res?.gross[0]),
          //   id: 0,
          // },
          {
            valid: res?.incomeFromSalaries ? true : false,
            name: "Total Salary",
            amt: calcIncome(),
            id: 0,
          },
          // {
          //   valid: res?.recRent[0] ? true : false,
          //   name: "Rent Income",
          //   amt: Number(res?.recRent[0]),
          //   id: 1,
          // },
          {
            valid: res?.incomeFromHouseProperty ? true : false,
            name: "Rent Income",
            amt: calcRent(),
            id: 1,
          },
          {
            valid: res?.incomeFromOtherSources ? true : false,
            name: "Interest Income",
            // amt: Number(res?.interest[0]),
            amt: calcInterest(),
            id: 2,
          },
          // {
          //   valid: res?.interest[0] ? true : false,
          //   name: "Interest Income",
          //   // amt: Number(res?.interest[0]),
          //   amt: calcInterest(),
          //   id: 2,
          // },
          // {
          //   valid: res?.interest[0] ? true : false,
          //   name: "First Interest Income",
          //   amt: Number(res?.interest[0]),
          //   id: 3,
          // },
          {
            valid: res?.incomeFromSalaries ? true : false,
            name: "Income from Other Employer",
            amt: calcIncomeOtherEmp(),
            id: 4,
          },
        ],
        userData: [
          {
            valid: res?.incomeFromSalaries ? true : false,
            name: "TAN",
            amt: res?.incomeFromSalaries?.[0]?.tan || "",
            id: 0,
          },
          {
            valid: res?.incomeFromSalaries ? true : false,
            name: "Designation",
            amt: res?.incomeFromSalaries?.[0]?.designation || "",
            id: 1,
          },
        ],
        allowancesData: [
          {
            askUser: true,
            valid: res?.incomeFromSalaries ? true : false,
            name: "Cost Allowance",
            amt: Number(res?.incomeFromSalaries[0]?.allowance?.uniform || 0),
            id: 0,
          },
          {
            // askUser: true,
            valid: res?.incomeFromSalaries ? true : false,
            name: "Children Education Allowance",
            amt: Number(res?.incomeFromSalaries[0]?.allowance?.edu || 0),
            id: 1,
          },
          {
            askUser: true,
            valid: res?.incomeFromSalaries ? true : false,
            name: "Rent Allowance",
            amt: Number(res?.incomeFromSalaries[0]?.allowance?.hra || 0),
            id: 2,
          },
          {
            askUser: true,
            valid: res?.incomeFromSalaries ? true : false,
            name: "Travel Allowance",
            amt: Number(res?.incomeFromSalaries[0]?.allowance?.travel || 0),
            id: 3,
          },
          {
            askUser: true,
            valid: res?.incomeFromSalaries ? true : false,
            name: "Other Allowances",
            amt: Number(res?.incomeFromSalaries[0]?.allowance?.other || 0),
            id: 4,
          }
        ],
        deductionData: [
          {
            askUser: false,
            valid: true,
            name: "Standard Deduction",
            amt: 50000,
            id: 0,
          },
          {
            askUser: false,
            valid: true,
            name: "Professional Fees",
            amt: 2500,
            id: 1,
          },
          // {
          //   askUser: false,
          //   valid: res?.tax[0] ? true : false,
          //   name: "Municipal Tax",
          //   amt: Number(res?.tax[0]),
          //   id: 2,
          // },
          // {
          //   askUser: false,
          //   valid: res?.recRent[0] ? true : false,
          //   name: "Rent Deduction",
          //   amt: calc24(),
          //   id: 3,
          // },
          {
            askUser: true,
            valid: calc80C() ? true : false,
            name: t("80C, 80CCC & 80CCD(1)"),
            amt: calc80C(),
            id: 4,
            max: 150000,
          },
          {
            askUser: true,
            valid: res?.deductions ? true : false,
            name: t("NPS: 80CCD(1B), 80CCD(2)"),
            // amt: Number(res?.nps[0]) > 50000 ? 50000 : Number(res?.nps[0]),
            amt: calc80CCD(),
            id: 5,
            max: 50000,
          },
          {
            askUser: true,
            valid: calc80D() ? true : false,
            name: "80D",
            amt: calc80D(),
            id: 6,
            max: 75000,
          },
          {
            askUser: true,
            // eslint-disable-next-line no-extra-boolean-cast
            valid: res?.deductions?.["80DD"] ? true : false,
            name: "80DD",
            amt: Number(res?.deductions?.["80DD"] || 0),
            id: 7,
            max: 125000,
          },
          {
            askUser: true,
            valid: calc80DDB() ? true : false,
            name: "80DDB",
            amt: calc80DDB(),
            id: 8,
            max: 140000,
          },
          {
            askUser: true,
            valid: res?.deductions?.["80G"] ? true : false,
            name: "80G",
            amt: Number(res?.deductions?.["80G"] || 0),
            id: 9,
          },
          {
            askUser: true,
            valid: res?.deductions?.["80GG"] ? true : false,
            name: "80GG",
            amt: calc80GG(),
            id: 10,
          },
          // {
          //   askUser: true,
          //   valid: res?.$80tta[0] ? true : false,
          //   name: "80TTA",
          //   amt: calc80TTA(),
          //   id: 19,
          // },
          {
            askUser: true,
            valid: calc80U() ? true : false,
            name: "80U",
            amt: calc80U(),
            id: 18,
          },
          {
            askUser: true,
            valid: res?.deductions?.["80TTA"] ? true : false,
            name: "80TTA",
            amt: calc80TTA(),
            id: 17,
          },
        ],
        suggestions: res?.suggestions,
      };
  
      return data;
    } else {
      const data = {
        incomeData: [
          {
            valid: false,
            name: "Total Salary",
            amt: 0,
            id: 0,
          },
          {
            valid: false,
            name: "Rent Income",
            amt: 0,
            id: 1,
          },
          {
            valid: false,
            name: "Interest Income",
            amt: 0,
            id: 3,
          },
          {
            valid: false,
            name: "Income from Other Employer",
            amt: 0,
            id: 4,
          },
        ],
        userData: [
          {
            valid: false,
            name: "TAN",
            amt: 0,
            id: 0,
          },
          {
            valid: false,
            name: "Designation",
            amt: 0,
            id: 1,
          },
        ],
        allowancesData: [
          {
            askUser: true,
            valid: res?.costTourAllow ? true : false,
            name: "Cost Allowance",
            amt: Number(res?.costTourAllow),
            id: 0,
          },
          {
            askUser: true,
            valid: res?.childEduAllow ? true : false,
            name: "Children Education Allowance",
            amt: Number(res?.childEduAllow),
            id: 1,
          },
          {
            askUser: true,
            valid: res?.rentAllow ? true : false,
            name: "Rent Allowance",
            amt: Number(res?.rentAllow),
            id: 2,
          },
          {
            askUser: true,
            valid: res?.TravelAllow ? true : false,
            name: "Travel Allowance",
            amt: Number(res?.travelAllow),
            id: 3,
          },
          {
            askUser: true,
            valid: res?.otherAllow ? true : false,
            name: "Other Allowances",
            amt: Number(res?.otherAllow),
            id: 4,
          }
        ],
        deductionData: [
          {
            askUser: false,
            valid: true,
            name: "Standard Deduction",
            amt: 50000,
            id: 0,
          },
          {
            askUser: false,
            valid: true,
            name: "Professional Fees",
            amt: 2500,
            id: 1,
          },
          {
            askUser: true,
            valid: false,
            name: "Municipal Tax Paid",
            amt: 0,
            id: 2,
          },
          {
            askUser: true,
            valid: false,
            name: "24",
            amt: 0,
            id: 3,
          },
          { askUser: true, valid: false, name: "80C", amt: 0, id: 4 },
          {
            askUser: true,
            valid: false,
            name: "80CCD(1B)",
            amt: 0,
            id: 5,
          },
          { askUser: true, valid: false, name: "80D", amt: 0, id: 6 },
          {
            askUser: true,
            valid: false,
            name: "80DD(1B)",
            amt: 0,
            id: 7,
          },
          {
            askUser: true,
            valid: false,
            name: "80DDB",
            amt: 0,
            id: 8,
          },
          { askUser: true, valid: false, name: "80G", amt: res?.$80g, id: 10 },
  
          {
            askUser: true,
            valid: false,
            name: "80GG",
            amt: 0,
            id: 11,
          },
  
          // {
          //   askUser: true,
          //   valid: false,
          //   name: "80TTA",
          //   amt: 0,
          //   id: 19,
          // },
  
          {
            askUser: true,
            valid: false,
            name: "80U",
            amt: 0,
            id: 18,
          },
  
  
          {
            askUser: true,
            valid: false,
            name: "Interest on Home Loan",
            amt: 0,
            id: 17,
          },
  
        ],
        suggestions: [
          false /* 0 */,
          false /* 1 */,
          false /* 2 */,
          false /* 3 */,
          true /* 4 */,
          true /* 5 */,
          true /* 6 */,
          true /* 7 */,
          true /* 8 */,
          true /* 9 */,
          true /* 10 */,
        ],
      };
  
      return data;
    }
  }

  // -----------------------------------------------------------------------
  const userHookData = useUserData(year);
  console.log("useUserData/userHookData: ", userHookData);
  const userHookTaxData = useUserTax(year);
  console.log(userHookTaxData);
  const currTaxData = userHookTaxData[year];
  // const currTaxData = userHookTaxData;
  const resData = userHookData[year];
  // resData = userHookData;
  console.log("reDAta: ", resData);
  console.log("data from current year :", userHookData);
  console.log("user Tax: ", currTaxData);
  const [res, setRes] = useState(analyseResponse(resData));
  const newRes = analyseResponse(resData);
  // const otherEmpPopSalary = userHookData[currentAY]?.incomeFromSalaries[1].salary;
  
  const usersData = useUser();
  const userPANLive = usersData?.userdata?.pan;
  // const otherEmpSalary = usersData?.incomeSalary[1].salary;
  // setotherEmp(usersData?.incomeSalary[1]?.salary);
  console.log("user data is :", usersData);
  console.log("Regime:", resData?.tax);
  console.log("res data is :", res);
  console.log("Newres data is :", newRes);
  // setSelectedRegime(resData?.tax);
  // setotherEmp(resData?.incomeFromSalaries[1]?.salary);

  // ----------------------------------------------------------------

  

  //------------------------------------ FORM16 PARTA DATA -------------------------------------------------

  const aprTax = currTaxData?.["Q1"]?.april?.taxDeposited ?? 0;
  const mayTax = currTaxData?.["Q1"]?.may?.taxDeposited ?? 0;
  const junTax = currTaxData?.["Q1"]?.june?.taxDeposited ?? 0;
  const julTax = currTaxData?.["Q2"]?.july?.taxDeposited ?? 0;
  const augTax = currTaxData?.["Q2"]?.august?.taxDeposited ?? 0;
  const sepTax = currTaxData?.["Q2"]?.september?.taxDeposited ?? 0;
  const octTax = currTaxData?.["Q3"]?.october?.taxDeposited ?? 0;
  const novTax = currTaxData?.["Q3"]?.november?.taxDeposited ?? 0;
  const decTax = currTaxData?.["Q3"]?.december?.taxDeposited ?? 0;
  const janTax = currTaxData?.["Q4"]?.january?.taxDeposited ?? 0;
  const febTax = currTaxData?.["Q4"]?.february?.taxDeposited ?? 0;
  const marTax = currTaxData?.["Q4"]?.march?.taxDeposited ?? 0;

  const taxTotal =
    aprTax +
    mayTax +
    junTax +
    julTax +
    augTax +
    sepTax +
    octTax +
    novTax +
    decTax +
    janTax +
    febTax +
    marTax;

  const sumQ1 = aprTax + mayTax + junTax;
  const sumQ2 = julTax + augTax + sepTax;
  const sumQ3 = octTax + novTax + decTax;
  const sumQ4 = janTax + febTax + marTax;

  const taxDeductedSum = sumQ1 + sumQ2 + sumQ3 + sumQ4;

  const ReceiptNoQ1 = currTaxData?.["Q1"]?.recNo ?? 0;
  const ReceiptNoQ2 = currTaxData?.["Q2"]?.recNo ?? 0;
  const ReceiptNoQ3 = currTaxData?.["Q3"]?.recNo ?? 0;
  const ReceiptNoQ4 = currTaxData?.["Q4"]?.recNo ?? 0;

  const recNoApr = currTaxData?.["Q1"]?.april?.recNo24G ?? 0;
  const recNoMay = currTaxData?.["Q1"]?.may?.recNo24G ?? 0;
  const recNoJun = currTaxData?.["Q1"]?.june?.recNo24G ?? 0;
  const recNoJul = currTaxData?.["Q2"]?.july?.recNo24G ?? 0;
  const recNoAug = currTaxData?.["Q2"]?.august?.recNo24G ?? 0;
  const recNoSep = currTaxData?.["Q2"]?.september?.recNo24G ?? 0;
  const recNoOct = currTaxData?.["Q3"]?.october?.recNo24G ?? 0;
  const recNoNov = currTaxData?.["Q3"]?.november?.recNo24G ?? 0;
  const recNoDec = currTaxData?.["Q3"]?.december?.recNo24G ?? 0;
  const recNoJan = currTaxData?.["Q4"]?.january?.recNo24G ?? 0;
  const recNoFeb = currTaxData?.["Q4"]?.february?.recNo24G ?? 0;
  const recNoMar = currTaxData?.["Q4"]?.march?.recNo24G ?? 0;

  const DDOApr = currTaxData?.["Q1"]?.april?.DDO ?? 0;
  const DDOMay = currTaxData?.["Q1"]?.may?.DDO ?? 0;
  const DDOJun = currTaxData?.["Q1"]?.june?.DDO ?? 0;
  const DDOJul = currTaxData?.["Q2"]?.july?.DDO ?? 0;
  const DDOAug = currTaxData?.["Q2"]?.august?.DDO ?? 0;
  const DDOSep = currTaxData?.["Q2"]?.september?.DDO ?? 0;
  const DDOOct = currTaxData?.["Q3"]?.october?.DDO ?? 0;
  const DDONov = currTaxData?.["Q3"]?.november?.DDO ?? 0;
  const DDODec = currTaxData?.["Q3"]?.december?.DDO ?? 0;
  const DDOJan = currTaxData?.["Q4"]?.january?.DDO ?? 0;
  const DDOFeb = currTaxData?.["Q4"]?.february?.DDO ?? 0;
  const DDOMar = currTaxData?.["Q4"]?.march?.DDO ?? 0;

  const salaryQ1 = currTaxData?.["Q1"]?.salary ?? 0;
  const salaryQ2 = currTaxData?.["Q2"]?.salary ?? 0;
  const salaryQ3 = currTaxData?.["Q3"]?.salary ?? 0;
  const salaryQ4 = currTaxData?.["Q4"]?.salary ?? 0;

  const salaryTotal = salaryQ1 + salaryQ2 + salaryQ3 + salaryQ4;

  const dateApr = currTaxData?.["Q1"]?.april?.date ?? 0;
  const dateMay = currTaxData?.["Q1"]?.may?.date ?? 0;
  const dateJun = currTaxData?.["Q1"]?.june?.date ?? 0;
  const dateJul = currTaxData?.["Q2"]?.july?.date ?? 0;
  const dateAug = currTaxData?.["Q2"]?.august?.date ?? 0;
  const dateSep = currTaxData?.["Q2"]?.september?.date ?? 0;
  const dateOct = currTaxData?.["Q3"]?.october?.date ?? 0;
  const dateNov = currTaxData?.["Q3"]?.november?.date ?? 0;
  const dateDec = currTaxData?.["Q3"]?.december?.date ?? 0;
  const dateJan = currTaxData?.["Q4"]?.january?.date ?? 0;
  const dateFeb = currTaxData?.["Q4"]?.february?.date ?? 0;
  const dateMar = currTaxData?.["Q4"]?.march?.date ?? 0;

  //----------------------------------FORM16 PARTA----------------------------------------------------

  const [Name, setName] = useState("");
  const [empId, setempId] = useState("");
  const [pan, setpan] = useState("");
  const [officeAddress, setofficeAddress] = useState(usersData?.officeAddress);

  const [tax, setTax] = useState(TotalTaxPaid(userData?.taxPaid));
  const [showDeduction, setShowDeduction] = useState(false);

  // const userHookRes = analyseRes(userHookData?.question);
  const userHookRes = res;

  const userHookTax = TotalTaxPaid(userHookData?.taxPaid);
  const getNotice = async () => {
    const docRef = doc(db, "notices", "notice");
    const docSnap = await getDoc(docRef);
    setNotice(docSnap.data()?.notice);
  };

  useEffect(() => {
    setotherEmp(resData?.incomeFromSalaries[1]?.salary || "0");
    setSelectedRegime(resData?.tax || "Old Regime");
  }, [resData]);

  useEffect(() => {
    setUserData(userHookData);
    setData(Data);
    setTax(userHookTax);
    // setRes(analyseRes(userHookData));
    getNotice();
  }, []);
  useEffect(() => {
    // setRes(userHookRes);
    setTax(userHookTax);
    setSelectedRegime(resData?.tax || "Old Regime");
    // console.log("res is set:", res);
  }, [userHookData, usersData]);
  useEffect(() => {
    if (prevYearRef.current !== year) {
      console.log("Year Changed");
      prevYearRef.current = year;
      setUserData(userHookData);
      // setRes(userHookRes);

      setTax(userHookTax);
    }
  }, [year, userHookData, res, userHookRes]);

  let needForDeduction = true;
  let dispalyDeduction = true;
  if (TotalTaxableIncome(res.incomeData, res.deductionData) <= 0) {
    needForDeduction = false;
  }

  console.log(selectedRegime);

  const getUserDetails = async () => {
    const match = auth.currentUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();

    const docRef = doc(db, "users", pan);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const name = data.name;

    return name;
  };

  const address = usersData?.userdata?.officeAddress;
  const addressMaxWords = 8;
  let addressLine1 = "";
  let addressLine2 = "";

  if (address) {
    const words = address.split(" ");
    addressLine1 = words.slice(0, addressMaxWords).join(" ");
    addressLine2 = words.slice(addressMaxWords).join(" ");
  }

  const QuarterObj = {
    sumQ1: sumQ1,
    sumQ2: sumQ2,
    sumQ3: sumQ3,
    sumQ4: sumQ4,
    taxDeductedSum: taxDeductedSum,
    ReceiptNoQ1: ReceiptNoQ1,
    ReceiptNoQ2: ReceiptNoQ2,
    ReceiptNoQ3: ReceiptNoQ3,
    ReceiptNoQ4: ReceiptNoQ4,
    salaryQ1: salaryQ1,
    salaryQ2: salaryQ2,
    salaryQ3: salaryQ3,
    salaryQ4: salaryQ4,
    salaryTotal: salaryTotal,
    aprTax: aprTax,
    mayTax: mayTax,
    junTax: junTax,
    julTax: julTax,
    augTax: augTax,
    sepTax: sepTax,
    octTax: octTax,
    novTax: novTax,
    decTax: decTax,
    janTax: janTax,
    febTax: febTax,
    marTax: marTax,
    taxTotal: taxTotal,
    recNoApr: recNoApr,
    recNoMay: recNoMay,
    recNoJun: recNoJun,
    recNoJul: recNoJul,
    recNoAug: recNoAug,
    recNoSep: recNoSep,
    recNoOct: recNoOct,
    recNoNov: recNoNov,
    recNoDec: recNoDec,
    recNoJan: recNoJan,
    recNoFeb: recNoFeb,
    recNoMar: recNoMar,
    DDOApr: DDOApr,
    DDOMay: DDOMay,
    DDOJun: DDOJun,
    DDOJul: DDOJul,
    DDOAug: DDOAug,
    DDOSep: DDOSep,
    DDOOct: DDOOct,
    DDONov: DDONov,
    DDODec: DDODec,
    DDOJan: DDOJan,
    DDOFeb: DDOFeb,
    DDOMar: DDOMar,
    dateApr: dateApr,
    dateMay: dateMay,
    dateJun: dateJun,
    dateJul: dateJul,
    dateAug: dateAug,
    dateSep: dateSep,
    dateOct: dateOct,
    dateNov: dateNov,
    dateDec: dateDec,
    dateJan: dateJan,
    dateFeb: dateFeb,
    dateMar: dateMar,
    DedPan: "DedPAN",
    DedTan: "DedTAN",
  };

  console.log("quarterObj is : ", QuarterObj);

  const userObject = {
    year: year,
    name: usersData?.userdata?.name,
    empId: usersData?.userdata?.employeeId,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    pan: userHookData?.pan,
    // tan: Object.values(usersData?.incomeSalary)[0]?.tan || "",
    tan: newRes.userData[0].amt || "",
    uid: userHookData?.uid,
    // Designation: Object.values(usersData?.incomeSalary)[0]?.designation || "",
    Designation: newRes.userData[1].amt || "",
  };

  const calculateDeductionTotal = (section) => {
    let sum = 0;
    for (
      let index = 0;
      index < userHookData?.question?.[section].length;
      index++
    ) {
      const current = parseInt(userHookData?.question?.[section][index]);
      sum = sum + current;
    }
    return sum;
  };

  let salary = newRes.incomeData[0].amt;
  console.log("Primary Gross: ", salary);
  let gSalaryB = newRes.incomeData[3].amt;
  let gSalaryC = 0;
  let gSalaryD = 0;
  let uniformAll = newRes.allowancesData[0].amt;
  let eduAll = newRes.allowancesData[1].amt;
  let HRA = newRes.allowancesData[2].amt;
  let WATA = newRes.allowancesData[3].amt;
  let otherAll = newRes.allowancesData[4].amt;
  //console.log("travel allowance is :", WATA);
  //console.log("house rent allowance :", HRA);
  let ExcPayRec =
    newRes.allowancesData[0].amt +
    newRes.allowancesData[1].amt +
    newRes.allowancesData[4].amt;
  //console.log("other allowances are :", ExcPayRec);
  let totalgross = salary + gSalaryB + gSalaryC + gSalaryD;
  let AllowanceTotal = WATA + HRA + ExcPayRec;
  //console.log("allowances total:", AllowanceTotal);
  let Balance3 = totalgross - AllowanceTotal;
  let standardDeduction = 0;
  let taxOnEmployment = 0;
  if (selectedRegime == "New Regime") {
    standardDeduction = 75000;
  } else {
    standardDeduction = 50000;
    taxOnEmployment = 2500;
  }
  let entertainmentAllowance = 0;
  let Aggregate5 = standardDeduction + entertainmentAllowance + taxOnEmployment;
  let incomeChargableSalaries = Balance3 - Aggregate5;
  // const homeLoanIndex = res.deductionData.findIndex(
  //   (item) => item.name === "Interest on Home Loan"
  // );

  // const interestonhomeloan = res.deductionData[homeLoanIndex].amt;

  //console.log("Interest on Home Loan:", interestonhomeloan);

  // let houseProperty = res.incomeData[1].amt - interestonhomeloan;
  let houseProperty = newRes.incomeData[1].amt;
  //console.log("houseProperty", houseProperty);
  let otherIncome = newRes.incomeData[2].amt;
  let total7 = houseProperty + otherIncome;
  let grossTotalIncome = incomeChargableSalaries + total7;
  // let $80c = calculateDeductionTotal("$80c");
  let $80c = newRes.deductionData[2].amt;
  // let $80CCD1B = newRes.deductionData[3].amt;
  let $80CCD1B = resData?.deductions?.["80CCD"]?.["80CCD-1B"] || 0;
  let $80CCD2 = resData?.deductions?.["80CCD"]?.["80CCD-2"] || 0;
  let $80d = newRes.deductionData[4].amt;
  let $80dd = newRes.deductionData[5].amt;
  let $80ddb = newRes.deductionData[6].amt;
  let $80G = newRes.deductionData[7].amt;
  let $80gg = newRes.deductionData[8].amt;
  let $80U = newRes.deductionData[9].amt;
  let $80tta = newRes.deductionData[10].amt;
  let $80Total = $80c < 150000 ? $80c : 150000;
  // let $80gg =
  //   calculateDeductionTotal("$80c") < 60000
  //     ? calculateDeductionTotal("$80c")
  //     : 60000;
  //console.log("$80gg : ", $80gg);
  // let $80d = res.deductionData[6].amt ? res.deductionData[6].amt : 0;
  // console.log("$80d : ", $80d);
  // let $80dd = res.deductionData[7].amt ? res.deductionData[7].amt : 0;

  // let $80ddb = res.deductionData[8].amt ? res.deductionData[8].amt : 0;
  // console.log("$80ddb : ", $80ddb);
  // let $80CCD1B = res.deductionData[5].amt;
  //console.log("$80CCD1B : ", $80CCD1B);
  //console.log("userType is :", usersData?.userType);
  // let $80CCD2 = 0;

  // if (
  //   usersData?.userType === "Police" ||
  //   usersData?.userType === "Defence Personal" ||
  //   usersData?.userType === "Government Employee"
  // ) {
  //   $80CCD2 = parseInt(grossTotalIncome * 0.14);
  // } else if (
  //   usersData?.userType === "Self Employed" ||
  //   usersData?.userType === "Freelancer" ||
  //   usersData?.userType === "Student" ||
  //   usersData?.userType === "Private Corporate Employee"
  // ) {
  //   $80CCD2 = parseInt(grossTotalIncome * 0.1);
  // } else {
  // Handle other cases or set a default value for $80CCD2
  //   $80CCD2 = 0; // Default value
  // }

  console.log("$80CCD2: ", $80CCD2);

  //console.log("80ccd2 : ", $80CCD2);
  // let $80U = res.deductionData[11].amt ? res.deductionData[11].amt : 0;
  //console.log("$80U : ", $80U);
  let $80E = 0;
  // let $80G = res.deductionData[9].amt ? res.deductionData[9].amt : 0;
  //console.log("$80G : ", $80G);
  // let $80EEB;
  // if (
  //   res.incomeData &&
  //   res.incomeData.length > 3 &&
  //   res.incomeData[3].amt !== undefined
  // ) {
  //   const amount = Number(res.incomeData[3].amt);
  //   $80EEB = amount > 10000 ? 10000 : amount;
  // } else {
  //   $80EEB = 0;
  // }
  let DeductionB =
    $80CCD1B +
    $80CCD2 +
    $80gg +
    $80d +
    $80dd +
    $80ddb +
    $80U +
    $80E +
    $80G +
    $80tta;
  console.log("deductions :", DeductionB);
  // let Aggregate10 = $80Total + DeductionB;
  let Aggregate10 = $80c + DeductionB;
  let totalIncome = 0;
  let taxOnTotalIncome = 0;
  let Rebate = 0;
  if (selectedRegime == "New Regime") {
    totalIncome = grossTotalIncome - $80CCD2;
    taxOnTotalIncome = Number(
      TotalTaxableIncome(grossTotalIncome, $80CCD2, "New")
    );
    Rebate = totalIncome <= 700000 ? taxOnTotalIncome : 0;
  } else {
    totalIncome = grossTotalIncome - Aggregate10;
    taxOnTotalIncome = Number(
      TotalTaxableIncome(grossTotalIncome, Aggregate10, "Old")
    );
    Rebate = totalIncome <= 500000 ? taxOnTotalIncome : 0;
  }
  console.log("total tax :", taxOnTotalIncome);
  console.log("rebate :", Rebate);
  let taxPayableAfterRebate = taxOnTotalIncome - Rebate;
  console.log("tax payable after rebate :", taxPayableAfterRebate);
  let EducationCess = taxPayableAfterRebate * 0.04;
  console.log("educationcess :", EducationCess);
  let taxPayable = Math.floor(taxPayableAfterRebate + EducationCess);
  console.log("tax payable :", taxPayable);
  let reliefUser89 = 0;
  let taxPayable16 = taxPayable - reliefUser89;
  let taxDeductedAtSource = 0;
  let taxDeductedByOtherEmployee = 0;
  let BalanceTaxPayable = 0;
  let showPopGrossIncome = totalgross + houseProperty + otherIncome;
  let showPopAllowance = uniformAll + eduAll + HRA + WATA + otherAll;
  let showPopDeduction = $80c + $80CCD1B + $80CCD2 + $80d + $80dd + $80ddb + $80G + $80gg + $80U + $80tta;
  let showPopTaxpayable = taxOnTotalIncome;
  let showPopRebate = Rebate;
  let showPopTaxpayableAfterRebate = taxPayableAfterRebate;
  let showPopEducationCess = EducationCess;
  let showPopTotalTaxPayable = taxPayable16;
    
  const userDeduction = {
    showPopGrossIncome : showPopGrossIncome,
    showPopAllowance : showPopAllowance,
    showPopDeduction: showPopDeduction,
    showPopTaxpayable: showPopTaxpayable,
    showPopRebate: showPopRebate,
    showPopTaxpayableAfterRebate: showPopTaxpayableAfterRebate,
    showPopEducationCess: showPopEducationCess,
    showPopTotalTaxPayable: showPopTotalTaxPayable,
    salary: salary.toString(),
    gSalaryB: gSalaryB,
    gSalaryC: gSalaryC,
    gSalaryD: gSalaryD,
    totalgross: totalgross,
    uniformAll: uniformAll,
    eduAll: eduAll,
    HRA: HRA,
    WATA: WATA,
    otherAll: otherAll,
    ExcPayRec: ExcPayRec,
    AllowanceTotal: AllowanceTotal,
    Balance3: Balance3,
    standardDeduction: standardDeduction,
    entertainmentAllowance: entertainmentAllowance,
    taxOnEmployment: taxOnEmployment,
    Aggregate5: Aggregate5,
    incomeChargableSalaries: incomeChargableSalaries,
    houseProperty: houseProperty,
    otherIncome: otherIncome,
    total7: total7,
    grossTotalIncome: grossTotalIncome,
    $80c: $80c.toString(),
    $80Total: $80Total,
    $80gg: $80gg,
    $80d: $80d,
    $80dd: $80dd,
    $80ddb: $80ddb,
    $80CCD1B: $80CCD1B,
    $80CCD2: $80CCD2,
    $80U: $80U,
    $80E: $80E,
    $80G: $80G,
    $80EEB: $80tta,
    Aggregate10: Aggregate10,
    totalIncome: totalIncome,
    taxOnTotalIncome: taxOnTotalIncome,
    Rebate: Rebate,
    EducationCess: EducationCess,
    taxPayable: taxPayable,
    reliefUser89: reliefUser89,
    taxPayable16: taxPayable16,
    taxDeductedAtSource: taxDeductedAtSource,
    taxDeductedByOtherEmployee: taxDeductedByOtherEmployee,
    BalanceTaxPayable: BalanceTaxPayable,
    regime: selectedRegime,
  };
  console.log("length of the array is ", newRes.incomeData);

  const covertToNumber = (arr = []) => {
    let newArr = [];
    arr.forEach((item, index) => {
      newArr[index] = item.toString().split(",").join("");
    });
    return newArr;
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    document.body.classList.toggle("popup-open");
  };

  const submitForm = async () => {
    console.log("otherEmp is ", otherEmp);
    const currentAY = process.env.REACT_APP_CURRENT_AY;
    const pan = usersData?.userdata?.pan;
    const docRef = doc(db, "itrWorking", pan);

    try {
      // Check if the document exists
      const docSnapshot = await getDoc(docRef);
      const updates = {};

      if (docSnapshot.exists()) {
        // Document exists, update it
        const updates = {
          [`${currentAY}.incomeFromSalaries`]: arrayUnion({
            salary: parseInt(otherEmp)
          })
        };
        await updateDoc(docRef, updates, { merge : true }); // Convert otherEmp to a number before updating
        console.log("Database updated successfully");
      } else {
        console.error(`No document found with PAN ${pan} in ${currentAY}`);
        // Handle the case where the document doesn't exist
        // You can either create a new document or show an error message
      }
    } catch (error) {
      console.error("Error updating database: ", error);
      // Add error handling here
    }
  };
  console.log(userHookData);

  return (
    <div className="container-md">
      <div className="px-2 year-box text-center">
        <img src={logo} className="app-logo" alt={t("logo")} />
        <YearSelector year={year} setYear={setYear} />
      </div>
      <div className="text-center mt-5">
        <select value={selectedRegime} onChange={handleStatusChange} className="regime-dropdown">
          <option value="">Select Regime</option>
          <option value="Old Regime">Old</option>
          <option value="New Regime">New</option>
        </select>
      </div>
      <div>
        <Status userPAN={userPAN} ay={year} />
      </div>
      <div className="p-2 d-flex flex-column align-items-stretch">
        <marquee className="m-2 text-danger py-2">{notice}</marquee>
        {!loading ? (
          newRes.deductionData.map((item) => {
            if (
              newRes.suggestions[item.id] &&
              dispalyDeduction &&
              needForDeduction
            ) {
              dispalyDeduction = false;
              return (
                <ListItem
                  value1={ item.name}
                  value2={item.max ? item.max : null}
                  bgcolor="green"
                  color2="highlight"
                  key={item.id}
                  navi={true}
                  disabled={true}
                  link={`/questions/${item.id}/${year}`}
                />
              );
            }
          })
        ) : (
          <div>{t("Fetching Data")}</div>
        )}
      </div>

      <div className="p-2">
        <h2>{t("Annual Income")}</h2>
        <div className="card bg-light-gray p-2">
          {!loading ? (
            <>
              <CardField
                label={t("Income from Salary")}
                value={
                  newRes.incomeData[0].amt < 0 ? 0 : newRes.incomeData[0].amt ?? null
                }
                key={0}
                link={`/questions/0/${year}`}
              />
              <CardField
                label={t("Income from Rent Sources")}
                value={newRes.incomeData[1].amt ?? null}
                key={1}
                link={`/questions/1/${year}`}
              />
              <CardField
                label={t("Income from Other Sources")}
                value={newRes.incomeData[2].amt ?? null}
                key={2}
                link={`/questions/3/${year}`}
              />
              {!loading &&
                resData?.incomeFromSalaries &&
                typeof resData.incomeFromSalaries === "object" && (
                Object.values(resData.incomeFromSalaries).slice(1).map((emp, index) => (
                  <div key={index} className="bg-white text-secondary rounded-pill d-flex justify-content-between py-2 px-3 m-1">
                    <span className="label" style={{ alignSelf: "center" }}>
                      {t("Income from Other Employers")}
                    </span>
                    <div className="right-content d-flex align-items-center">
                      <span style={{ marginLeft: "5px" }}>₹{emp.salary}</span>
                      <button
                        type="button"
                        style={{ height: "36px", width: "40px", padding: "0px" }}
                        className="btn btn-dark bg-green d-flex justify-content-center align-items-center rounded-pill border-0 edit-button-dashboard ms-1"
                        onClick={() => setShowPopup(true)}
                      ></button>
                    </div>
                  </div>
                ))
              )}
              {/* {!loading &&
                resData?.incomeFromSalaries &&
                Array.isArray(resData.incomeFromSalaries) &&
                resData.incomeFromSalaries.length >= 1 && (
                <div className="bg-white text-secondary rounded-pill d-flex justify-content-between py-2 px-3 m-1">
                  <span className="label" style={{ alignSelf: "center" }}>
                    {t("Income from Other Employers")}
                  </span>
                  <div className="right-content d-flex align-items-center">
                    <span style={{ marginLeft: "5px" }}>₹{resData.incomeFromSalaries[1].salary}</span>
                    <button
                      type="button"
                      style={{ height: "36px", width: "40px" }}
                      className="btn btn-dark bg-green d-flex justify-content-center align-items-center rounded-pill border-0 edit-button-dashboard ms-1"
                      onClick={() => setShowPopup(true)}
                    ></button>
                  </div>
                </div>
              )} */}
            </>
          ) : (
            <div>{t("Fetching Data")}</div>
          )}
          {showPopup && (
            <div className="custom-popup">
              <div className="popup-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="taxPaybleLabel">
                    {t("Income from Other Employers")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={togglePopup}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body taxPayable-modal-body">
                  <div className="mt-2 d-flex justify-content-around flex-wrap">
                    <div className="quarter quater-1">
                      <div className="inner-quarter">
                        Name: {""}
                        <span>
                          {resData?.incomeFromSalaries && resData.incomeFromSalaries.length > 0
                            ? resData.incomeFromSalaries[1].employerName || 0
                            : 0}
                        </span>
                      </div>
                    </div>
                    <div className="quarter quater-2">
                      <div className="inner-quarter">
                        TAN Number:{" "}
                        <span>
                          {resData?.incomeFromSalaries && resData.incomeFromSalaries.length > 0
                            ? resData.incomeFromSalaries[1].tan || 0
                            : 0}
                        </span>
                      </div>
                    </div>
                    <div className="quarter quater-4">
                      <div className="inner-quarter">
                        Gross Salary:{" "}
                        <input
                          type="text"
                          label="enter the Income From Other Employer"
                          value={otherEmp}
                          onChange={(e) => setotherEmp(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={togglePopup}
                  >
                    {t("Close")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      submitForm();
                      togglePopup(); // Close the pop-up after submitting
                    }}
                  >
                    {t("Submit")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="p-2">
        <h2>{t("Salary Exemption")}</h2>
        <div className="card bg-light-gray p-2">
          {!loading ? (
            <>
              <CardField
                label={t(
                  "Cost of travel on tour or transfer, maintainance of uniform"
                )}
                value={newRes.allowancesData[0].amt || 0}
                key={4}
                link={`/questions/12/${year}`}
              />
              <CardField
                label={t("Children Education allowance, Hostel allowance")}
                value={newRes.allowancesData[1].amt || 0}
                key={5}
                link={`/questions/13/${year}`}
              />
              <CardField
                label={t("House Rent Allowance")}
                value={newRes.allowancesData[2].amt || 0}
                key={6}
                link={`/questions/14/${year}`}
              />
              {/* <div data-bs-toggle="modal" data-bs-target="#incomeEmployers"> */}
              <CardField
                label={t("Travel Allowance")}
                value={newRes.allowancesData[3].amt || 0}
                key={7}
                link={`/questions/15/${year}`}
              />
              <CardField
                label={t("Other Allowances")}
                value={newRes.allowancesData[4].amt || 0}
                key={8}
                link={`/questions/16/${year}`}
              />
              {/* </div> */}
            </>
          ) : (
            <div>{t("Fetching Data")}</div>
          )}
        </div>
      </div>

      <div className="p-2">
        <h2>{t("Deductions Applicable")}</h2>
        <div className="card bg-light-gray p-2">
          {!loading ? (
            newRes.deductionData.map((item) => {
              return (
                (item.valid || showDeduction) &&
                !(item.id >= 0 && item.id <= 3) && (
                  <CardField
                    label={t(item.name)}
                    value={item.amt}
                    key={item.id}
                    link={`/questions/${item.id}/${year}`}
                  />
                )
              );
            })
          ) : (
            <div>{t("Fetching Data")}</div>
          )}
          <div className="d-flex flex-row-reverse px-2 pt-2">
            <ShowAllButton
              value={showDeduction ? t("Show Less") : t("Show All")}
              onTap={() => setShowDeduction(!showDeduction)}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div>{t("Fetching Data")}</div>
      ) : (
        <div className="p-2 d-flex flex-column align-items-stretch">
          {/* <ListItem
            disabled={true}
            value1="Total Tax Payable:"
            value2={`Rs. ${TotalTaxableIncome(
              res.incomeData,
              res.deductionData
            )}`}
            bgcolor="orange"
            color2="highlight"
            onClick={modifyPdf}
          /> */}
          <div
            className={
              "bg-orange px-4 py-2 my-2 rounded d-flex justify-content-center align-items-center object-fit: contain"
            }
            style={{ cursor: "pointer" }}
            data-bs-toggle="modal"
            data-bs-target="#taxPayable"
          >
            <span className={"text-highlight px-1"}>
              {t("Total Tax Payable")}
            </span>
            <span className={"text-highlight px-1"}>Rs.{taxPayable16}</span>
          </div>
          <div
            className="modal fade"
            id="taxPayable"
            style={{ marginTop: "35px" }}
            tabIndex="-1"
            aria-labelledby="taxPayableLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="taxPayableLabel">
                    {t("Total Tax Payable")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body taxPayable-modal-body">
                  <div className="mt-2 d-flex justify-content-around flex-wrap">
                    <div className="quarter quater-1">
                      <div className="inner-quarter">
                        {" "}
                        {t("Gross Income")} : <span>₹. {userDeduction.showPopGrossIncome}</span>
                      </div>
                    </div>
                    <div className="quarter quater-2">
                      <div className="inner-quarter">
                        {t("Standard Deduction + PT")} : <span>₹. 52500</span>
                      </div>
                    </div>
                    {/* <div className="quarter quater-3">
                      <div className="inner-quarter">
                        80C : <span> ₹. {userDeduction.$80c}</span>
                      </div>
                      <div className="inner-quarter">
                        80CCC : <span>₹. 0</span>
                      </div>
                      <div className="inner-quarter">
                        80CCD : <span>₹. {userDeduction.$80CCD1B}</span>{" "}
                      </div>
                    </div> */}
                    <div className="quarter quater-3">
                      <div className="inner-quarter">
                        {t("Total Allowance")} : <span>₹. {userDeduction.showPopAllowance}</span>
                      </div>
                    </div>
                    <div className="quarter quater-4">
                      <div className="inner-quarter">
                        {t("Total Deduction")} : <span>₹. {userDeduction.showPopDeduction}</span>
                      </div>
                    </div>
                    <div className="quarter quater-5">
                      <div className="inner-quarter">
                        {t("Tax Payable")} : <span>₹. {userDeduction.showPopTaxpayable}</span>
                      </div>
                      <div className="inner-quarter">
                        {t("Rebate u/s 87A")} : <span>₹. {userDeduction.showPopRebate}</span>
                      </div>
                      <div className="inner-quarter">
                        {t("Tax Payable after Rebate")} : <span>₹. {userDeduction.showPopTaxpayableAfterRebate}</span>
                      </div>
                      <div className="inner-quarter">
                        {t("Education Cess at 4%")} : <span>₹. {userDeduction.showPopEducationCess}</span>
                      </div>
                    </div>
                    <div className="quarter quater-9">
                      <div className="inner-quarter">
                        {t("Total Tax Payable")} : <span>₹. {userDeduction.showPopTotalTaxPayable}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {t("Close")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => modifyPdf(userObject, userDeduction)}
                  >
                    {t("Download")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "bg-light px-4 py-2 my-2 rounded d-flex justify-content-center align-items-center"
            }
            style={{ cursor: "pointer" }}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <span className={"text-dark px-1"}>{t("Total Tax Paid")}:</span>
            <span className={"text-dark px-1"}>Rs. {taxTotal} </span>
          </div>
          <div
            className="modal fade"
            id="exampleModal"
            style={{ marginTop: "35px" }}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {t("Total Tax Paid")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body taxPayable-modal-body">
                  <div className="text-center mt-2 d-flex justify-content-around flex-wrap gap-3">
                    <div className="quarter quater-1">
                      <div className="inner-quarter">
                        {" "}
                        {t("Quarter")} 1 : <span>₹. {sumQ1}</span>
                      </div>
                    </div>
                    <div className="quarter quater-2">
                      <div className="inner-quarter">
                        {t("Quarter")} 2 : <span>₹. {sumQ2}</span>
                      </div>
                    </div>
                    <div className="quarter quater-3">
                      <div className="inner-quarter">
                        {t("Quarter")} 3 : <span>₹. {sumQ3}</span>
                      </div>
                    </div>
                    <div className="quarter quater-4">
                      <div className="inner-quarter">
                        {t("Quarter")} 4 : <span>₹. {sumQ4} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {t("Close")}
                  </button>
                  {console.log(userObject)}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => PartApdf(userObject, QuarterObj)}
                  >
                    {t("Download")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ListItem
            value3={t("Send Data for Income Tax Working")}
            bgcolor={userHookData?.punch ? "blue" : "green"}
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
