/* eslint-disable quotes */
import React from "react";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";
import statePdf from "./pdfs/template.pdf";

async function modifyPdf(userObject, userDeduction) {
  console.log("modify pdf is:", userDeduction);
  const url = statePdf;
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  // const existingPdfBytes = fs.readFileSync(filePath);
  let X = 827;
  let Y = 1169;

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  // const { width, height } = firstPage.getSize();
  // console.log(width, height);

  let zeroth_column = 245;
  let first_column = 330;
  let second_column = 410;
  let third_column = 490;

  let dist = 13;
  let base = 728;

  const regime = userDeduction.regime;

  // -------------------- Section 0 (Header) Starts -------------------------------------
  base = 805;
  firstPage.drawText(userObject.year+" "+regime, {
    x: 46,
    y: base,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  base = 789;
  firstPage.drawText(userObject.name, {
    x: 50,
    y: base,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  base = 790;
  firstPage.drawText(userObject.empId, {
    x: 245,
    y: base,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  base = 780;
  firstPage.drawText(userObject.addressLine1, {
    x: 398,
    y: base,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  base = 771;
  firstPage.drawText(userObject.addressLine2, {
    x: 398,
    y: base,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  base = 778;
  firstPage.drawText(userObject.pan, {
    x: 40,
    y: base,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(userObject.tan, {
    x: 270,
    y: base,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  // -------------------- Section 0 (Header) Ends -------------------------------------

  // -------------------- Section 1 (Gross Salary) Starts -------------------------------------
  base = 728;
  firstPage.drawText(`Rs ${userDeduction?.salary}`, {
    x: first_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.gSalaryC}`, {
    x: first_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.gSalaryD}`, {
    x: first_column,
    y: base - dist * 2,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.gSalaryB}`, {
    x: first_column,
    y: base - dist * 3,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.totalgross}`, {
    x: second_column,
    y: base - dist * 4,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 1(Gross Salary) Ends -------------------------------------

  // -------------------- Section 2 (Less) Starts -------------------------------------
  base = 636;
  firstPage.drawText(`Rs ${userDeduction.WATA}`, {
    x: zeroth_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.HRA}`, {
    x: zeroth_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.ExcPayRec}`, {
    x: zeroth_column,
    y: base - dist * 2,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.AllowanceTotal}`, {
    x: second_column,
    y: base - dist * 3,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 2 (Less) Ends  -------------------------------------

  // -------------------- Section 3 (Balance) Starts -------------------------------------
  base = 584;
  firstPage.drawText(`Rs ${userDeduction.Balance3}`, {
    x: second_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 3 (Balance) Ends -------------------------------------

  // -------------------- Section 4 (Deduction) Starts -------------------------------------
  base = 560;
  firstPage.drawText(`Rs ${userDeduction.standardDeduction}`, {
    x: first_column,
    y: base - dist * 0,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.entertainmentAllowance}`, {
    x: first_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.taxOnEmployment}`, {
    x: first_column,
    y: base - dist * 2,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 4 (Deduction) Ends -------------------------------------

  // -------------------- Section 5 (Aggregate) Starts -------------------------------------
  base = 519;
  firstPage.drawText(`Rs ${userDeduction.Aggregate5}`, {
    x: second_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 5 (Aggregate) Ends -------------------------------------

  // -------------------- Section 6 (Income Chargable) Starts -------------------------------------
  base = 505;
  firstPage.drawText(`Rs ${userDeduction.incomeChargableSalaries}`, {
    x: third_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 6 (Income Chargable) Ends -------------------------------------

  // -------------------- Section 7 (Other Income) Starts -------------------------------------
  base = 466;
  firstPage.drawText(`Rs ${userDeduction.houseProperty}`, {
    x: zeroth_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.otherIncome}`, {
    x: zeroth_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  base = 442;
  firstPage.drawText(`Rs ${userDeduction.total7}`, {
    x: second_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 7 (Other Income) Ends -------------------------------------

  // -------------------- Section 8 (Gross Total Income) Starts -------------------------------------
  base = 427;
  firstPage.drawText(`Rs ${userDeduction.grossTotalIncome}`, {
    x: third_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 8 (Gross Total Income) Ends -------------------------------------

  // -------------------- Section 9 (Deduction under Chapter VIA) Starts -------------------------------------
  base = 390;

  // 9A
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction?.$80c}`, {
    //Rs 9Aa S
    x: second_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction?.$80Total}`, {
    x: third_column,
    y: base,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${0}`, {
    //80CCC
    x: second_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${0}`, { //80 CCD
    x: second_column,
    y: base - dist * 2,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  base = 337;
  // 9B First Column -------------
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80CCD1B}`, {
    x: first_column,
    y: base - dist * 0,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`Rs ${userDeduction.$80CCD2}`, {
    x: first_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80gg}`, {
    x: first_column,
    y: base - dist * 2,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80d}`, {
    x: first_column,
    y: base - dist * 3,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80dd}`, {
    x: first_column,
    y: base - dist * 4,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80ddb}`, {
    x: first_column,
    y: base - dist * 5,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80U}`, {
    x: first_column,
    y: base - dist * 6,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80E}`, {
    x: first_column,
    y: base - dist * 7,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80G}`, {
    x: first_column,
    y: base - dist * 8,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80EEB}`, {
    x: first_column,
    y: base - dist * 9,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  // 9B Second Column -----------
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80CCD1B}`, {
    x: second_column,
    y: base - dist * 0,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.$80CCD2}`, {
    x: second_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80gg}`, {
    x: second_column,
    y: base - dist * 2,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80d}`, {
    x: second_column,
    y: base - dist * 3,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80dd}`, {
    x: second_column,
    y: base - dist * 4,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80ddb}`, {
    x: second_column,
    y: base - dist * 5,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80U}`, {
    x: second_column,
    y: base - dist * 6,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80E}`, {
    x: second_column,
    y: base - dist * 7,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80G}`, {
    x: second_column,
    y: base - dist * 8,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80EEB}`, {
    x: second_column,
    y: base - dist * 9,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  // 9B Third Column --------------
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80CCD1B}`, {
    x: third_column,
    y: base - dist * 0,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.$80CCD2}`, {
    x: third_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80gg}`, {
    x: third_column,
    y: base - dist * 2,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80d}`, {
    x: third_column,
    y: base - dist * 3,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80dd}`, {
    x: third_column,
    y: base - dist * 4,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80ddb}`, {
    x: third_column,
    y: base - dist * 5,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80U}`, {
    x: third_column,
    y: base - dist * 6,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80E}`, {
    x: third_column,
    y: base - dist * 7,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80G}`, {
    x: third_column,
    y: base - dist * 8,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${regime == "New Regime" ? 0 : userDeduction.$80EEB}`, {
    x: third_column,
    y: base - dist * 9,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });

  // -------------------- Section 9 (Deduction under Chapter VIA) Ends -------------------------------------

  // -------------------- Section 10 - 18 (Other) Starts -------------------------------------
  base = 205;
  firstPage.drawText(`Rs ${regime == "New Regime" ? userDeduction.$80CCD2 : userDeduction.Aggregate10}`, {
    x: third_column,
    y: base - dist * 0,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.totalIncome}`, {
    x: third_column,
    y: base - dist * 1,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.taxOnTotalIncome}`, {
    x: third_column,
    y: base - dist * 2,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.Rebate}`, {
    x: third_column,
    y: base - dist * 3,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.EducationCess}`, {
    x: third_column,
    y: base - dist * 4,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.taxPayable }`, {
    x: third_column,
    y: base - dist * 5,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.reliefUser89}`, {
    x: third_column,
    y: base - dist * 6,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.taxPayable16}`, {
    x: third_column,
    y: base - dist * 7,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.taxDeductedAtSource}`, {
    x: second_column,
    y: base - dist * 8,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.taxDeductedByOtherEmployee}`, {
    x: second_column,
    y: base - dist * 9,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(`Rs ${userDeduction.BalanceTaxPayable}`, {
    x: third_column,
    y: base - dist * 10,
    size: 7,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  });
  // -------------------- Section 10 - 18 (Other) Ends -------------------------------------

  // console.log(`hello`)
  const pdfBytes = await pdfDoc.save();

  const pdfBlob = new Blob([pdfBytes], { type: `application/pdf` });

  // Save the PDF file using FileSaver.js
  saveAs(pdfBlob, `TS-Form16.pdf`);
}

// function App() {
//   return (
//     <div className=`App`>
//       <header className=`App-header`>
//         {/* <img src={logo} className=`App-logo` alt=`logo` /> */}
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <div>
//           <button onClick={modifyPdf}>Click me!</button>
//         </div>
//       </header>
//     </div>
//   );
// }

export default modifyPdf;
