import React from "react";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useQuery } from "@tanstack/react-query";

const useUser = (user = null) => {
  // used in profile.js
  const authUser = user === null ? auth.currentUser : user;
  // const authUser = auth.currentUser;
  // console.log("Auth User", user === null ? "auth user" : authUser?.uid);
  // console.log("Auth User", authUser?.uid);
  const FetchData = async () => {

    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    console.log(pan);

    const docRef = doc(db, "usersTable", pan);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      throw new Error("No such User Found!");
    }
    if (!docSnap.data()) {
      throw new Error("User data is undefined");
    }
    const userdata = docSnap.data();

    const itrworkRef = doc(db, "itrWorking", pan);
    const itrworkdocSnap = await getDoc(itrworkRef);

    const itrworkData = itrworkdocSnap.data();

    const currentYear = new Date().getFullYear();
    // Calculate the current, previous, and next assessment years
    const currentAY = `${currentYear}-${currentYear + 1}`;
    const previousAY = `${currentYear - 1}-${currentYear}`;

    const incomeSalary = itrworkData[currentAY]?.incomeFromSalaries;

    return { userdata, incomeSalary };
  };
  const {
    data: userData,
    isLoading,
    isError,
    error,
  } = useQuery(["User", authUser?.uid], FetchData, { initialData: {} });
  if (isLoading) {
    // console.log("Loading...");
    return <div>Loading...</div>;
  }
  if (isError) {
    console.log("Error", error);
    throw new Error(error);
  }

  // console.log(userData);
  return userData;
};

export default useUser;
