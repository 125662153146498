import React from "react";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useQuery } from "@tanstack/react-query";

const useUserTax = (ay) => {
  const authUser = auth.currentUser;
  const FetchData = async () => {
    const match = authUser.email.match(/\+(.*?)@/);
    const pan = match[1].toUpperCase();
    const docRef = doc(db, "taxPaid", pan);
    const docSnap = await getDoc(docRef);
    console.log(docSnap);
    if (!docSnap.exists()) {
      // throw new Error("No such User Found!");
      // console.log("No such User Found!");
      return {};
    }
    if (!docSnap.data()) {
      throw new Error("User data is undefined");
    }
    // return docSnap.data()[ay];
    return docSnap.data();
  };
  const {
    data: userData,
    isLoading,
    isError,
    error,
  } = useQuery(["UserTaxData", authUser?.uid, ay, "taxPaid"], FetchData, {
    initialData: {},
  });
  if (isLoading) {
    console.log("Loading...");
    return <div>Loading...</div>;
  }
  if (isError) {
    console.log("Error", error);
    throw new Error(error);
  }
  // console.log("Updated User", userData);
  return userData;
};

export default useUserTax;
