import React, { useState } from "react";

export const YearSelector = ({ setYear, year }) => {
  const currentYear = new Date().getFullYear();

  // Calculate the current, previous, and next assessment years
  const currentAY = `${currentYear}-${currentYear + 1}`;
  const previousAY = `${currentYear - 1}-${currentYear}`;
  const nextAY = `${currentYear + 1}-${currentYear + 2}`;

  // Initialize state with the current assessment year
  // const [selectedAY, setSelectedAY] = useState(previousAY);
  const [selectedAY, setSelectedAY] = useState(currentAY);

  // Array of assessment years
  const assessmentYears = [
    { value: previousAY, label: `AY ${previousAY}` },
    { value: currentAY, label: `AY ${currentAY}` },
    { value: nextAY, label: `AY ${nextAY}` },
  ];

  return (
    <div className="dropdown">
      <button
        className="btn year-drop d-flex btn-secondary dropdown-toggle dropdown-toggle-no-caret px-4 "
        style={{ background: "#454545" }}
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        AY {selectedAY}{" "}
        {/* <span className="dropdown-logo">
          <img src="/assets/images/icons/drop.png"></img>{" "}
        </span> */}
      </button>
      {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        {assessmentYears.map((ay) => (
          <li key={ay.value}>
            <button
              className="dropdown-item"
              type="button"
              onClick={() => {
                setSelectedAY(ay.value);
                setYear(ay.value);
              }}
            >
              {ay.label}
            </button>
          </li>
        ))}
      </ul> */}
      {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              setYear("2022-23");
            }}
          >
            AY 2022-23
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              setYear("2021-22");
            }}
          >
            AY 2021-22
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              setYear("2020-21");
            }}
          >
            AY 2020-21
          </button>
        </li>
      </ul> */}
    </div>
  );
};
