import React, { useState, useEffect } from "react";
import "./Status.css";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

const Status = ({ userPAN, ay }) => {
  const { t } = useTranslation();
  const [statusData, setStatusData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(-1); // Index of last active status

  const statusValues = [
    t("Manager Assigned"),
    t("ITR Password Generated"),
    t("Data Entered"),
    t("Data Punched"),
    t("Verification"),
    t("ITR Filed"),
    t("ITR Pending"),
    t("Refund Processed"),
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { userPAN: pan } = userPAN;
        // const currentYear = process.env.REACT_APP_CURRENT_AY;
        const statusRef = doc(db, "EnquirySheet", pan);
        const docSnap = await getDoc(statusRef);

        if (docSnap.exists()) {
          const statusObject = docSnap.data()["2024-2025"].Status;
          console.log(statusObject);
          setCurrentStatus(statusObject);
          // if (statusObject && typeof statusObject === "object") {
          //   const statusArray = Object.entries(statusObject)
          //     .filter(([_, value]) => value === true)
          //     .map(([key]) => key);
          //   setStatusData(statusArray);
          //   const lastIndex = statusArray.length - 1;
          //   setActiveIndex(lastIndex);
          // } else {
          //   console.log("Status field is not an object");
          //   setStatusData([]);
          //   setActiveIndex(-1);
          // }
        } else {
          console.log("Document does not exist!");
          // setStatusData([]);
          // setActiveIndex(-1);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching status data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [userPAN, ay]);

  // const statusValues = [
  //   t("Manager Assigned"),
  //   t("ITR Password Generated"),
  //   t("Data Entered"),
  //   t("Data Punched"),
  //   t("Verification"),
  //   t("ITR Filed"),
  //   t("ITR Pending"),
  //   t("Refund Processed"),
  // ];

  if (loading) {
    return <div>Loading...</div>;
  }

  const currentIndex = statusValues.findIndex(status => status === currentStatus);
  console.log(currentIndex);
  // Determine the indices of the statuses to display
  const indicesToDisplay = [];
  // if (activeIndex >= 0) {
  // Display last active status
  // indicesToDisplay.push(activeIndex);

  // Display status before the last active status
  // const beforeLastActiveIndex = activeIndex - 1;
  // if (beforeLastActiveIndex >= 0) {
  //   indicesToDisplay.push(beforeLastActiveIndex);
  // }

  // Display status after the last active status
  //   const afterLastActiveIndex = activeIndex + 1;
  //   if (afterLastActiveIndex < statusValues.length) {
  //     indicesToDisplay.push(afterLastActiveIndex);
  //   }
  // }
  if (currentIndex >= 0) {
    // Display previous status
    if (currentIndex > 0) {
      indicesToDisplay.push(currentIndex - 1);
    }

    // Display current status
    indicesToDisplay.push(currentIndex);

    // Display next status
    if (currentIndex < statusValues.length - 1) {
      indicesToDisplay.push(currentIndex + 1);
    }
  }


  // return (
  //   <div className="status-container">
  //     <div className="status-line p-bt-2">
  //       {statusValues.map((status, index) => {
  //         if (!indicesToDisplay.includes(index)) {
  //           return null; // Hide status if not in indicesToDisplay
  //         }
  //         return (
  //           <div key={status} className="status-item">
  //             <div
  //               className={`status-circle ${statusData.includes(status) ? "active" : ""
  //               }`}
  //             >
  //               {statusData.includes(status) && <span className="tick-mark">✓</span>}
  //             </div>
  //             <span className="status-label">{status}</span>
  //           </div>
  //         );
  //       })}
  //     </div>
  //     <div className="status-connector"></div>
  //   </div>
  // );

  return (
    <div className="status-container">
      <div className="status-line p-bt-2">
        {indicesToDisplay.map(index => {
          const status = statusValues[index];
          return (
            <div key={status} className="status-item">
              <div className={`status-circle ${index === currentIndex ? "active" : ""}`}>
                {index === currentIndex && <span className="tick-mark">✓</span>}
                {index === currentIndex - 1 && <span className="tick-mark">✓</span>}
              </div>
              <span className="status-label">{status}</span>
            </div>
          );
        })}
      </div>
      <div className="status-connector"></div>
    </div>
  );

};

export default Status;