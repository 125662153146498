import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const Library = () => {
  const { t } = useTranslation();
  const data = {
    questions: [
      [
        "What is your Annual Gross Salary for the Current Financial year?",
        "आपकी इस आर्थिक वर्ष की ग्रॉस सैलरी कितनी है?",
      ],
      [
        "Do you receive any Rent ? If yes, how much Monthly rent do you Receive?",
        "क्या आपको कही से रेंट आता है? अगर है, तो प्रति माह कितना आता?",
      ],
      [
        "What is the Amount of Municipal Tax Paid by you in the current Financial Year?",
        "आपके प्रॉपर्टी की इस आर्थिक वर्ष का म्युनिसिपल टैक्स कितना था?",
      ],
      [
        "Section 80C",
        "Section 80 C. Do you have any Interest from FDs, RDs, Or any other Investments ? If yes, what is the Total Interest Reveived?",
        "आपको इस आर्थिक वर्ष मे कोई इंटरेस्ट मिला है, जैसे FD का इंटरेस्ट या RD का इंटरेस्ट ?",
      ],
      [
        "Section 80C",
        "Do you have an LIC Policy, Home Loan, GPF, Tutuion Fees? What is the total Premium you pay?",
        "क्या आपकी कोई LIC POLICY, Home Loan, GPF, Tutuion Fees है? प्रीमियम अमाउंट बताये?",
      ],
      [
        "Section 80CCD(1B) & 80CCD(2)",
        "If you contribute to National Pension Scheme (NPS), write down annual amount?",
        "अगर आप, NATIONAL PENSION SCHEME मे अपना योगदान देते हो तो उसकी वार्षिक धनराशि बताये?",
      ],
      [
        "Section 80D",
        "If you pay to HEALTH INSURANCE PREMIUM, write down annual Amount?",
        "अगर आपका  HEALTH INSURANCE PREMIUM हो तो उसकी वार्षिक धनराशि बताये?",
      ], 
      [
        "Section 80DD",
        "Do you any permanent disability?", 
        "क्या आपको कोई विकलांगता है?"
      ],
      [
        "Section 80DDB",
        "Did you spend any money on your own or your family's medical treatment? If yes then how much",
        "क्या आपको अपने या अपने परिवार के चिकित्सा व्यवस्था के लिए कोई खर्च हुआ? अगर है तो कितना?",
      ],
      [
        "Section 80G",
        "Did you donate money to any charity? If yes then how much",
        "क्या आपने किसी अन्य व्यक्ति को धन दान किया? अगर है तो कितना?",
      ],
      [
        "Section 80GG",
        "Do you pay rent? If yes then how much?",
        "क्या आपको रेंट देना पड़ता है? अगर है तो कितना?",
      ],
      [
        "Income from other employer(s)?",
        "क्या आपको अन्य कोई नौकरी(या नियोक्ता) से आय है?",
      ],
      [
        "What is your cost of travel on tour or transfer, maintainance of uniform allowance?",
        "टूर या ट्रांसफर पर यात्रा की लागत, यूनिफॉर्म अलाउंस का रखरखाव क्या है?",
      ],
      [
        "Do you have any Children Education ,Hostel Allowance ?",
        "क्या आपके किसी बच्चे की शिक्षा, छात्रावास अलाउंस है?",
      ],
      [
        "Do you have any house rent allowance?",
        "क्या आपके पास कोई मकान किराया अलाउंस है?",
      ],
      [
        "Do you have any travel allowance?",
        "क्या आपके पास कोई यात्रा अलाउंस है?",
      ],
      [
        "Specify your other allowances if any",
        "अपने अन्य अलाउंस को निर्दिष्ट करें यदि कोई हो",
      ],
      [
        "Section 80TTA",
        "Do you have interest on Saving Bank? If yes then how much?",
        "क्या आपको सेविंग बैंक पर ब्याज है? अगर हाँ, तो कितना?",
      ],
      [
        "Section U",
        "Do you have Self Disability?",
        "क्या आपको खुद की अक्षमता है?",
      ],
    ],
  };

  return data;
};

export const responseSchema = {
  gross: [0],
  interest: [0, 0, 0, 0, 0],
  recRent: [0],
  tax: [0],
  nps: [0],
  $80c: [0, 0, 0, 0],
  $80d: [0, 0],
  $80ddb: [0, 0],
  $80g: [0],
  $80gg: [0],
  $80dd: [0],
  home: [0],
  $80u: [0, 0],
  otherEmp: [0],
  costTourAllow: [0],
  childEduAllow: [0],
  rentAllow: [0],
  TravelAllow: [0],
  otherAllow: [0],

  suggestions: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
    11: false,
  },
};

export const analyseRes = (res) => {
  console.log("response of deductions is ", res);
  if (res) {
    // const calc24 = () => {
    //   return (res?.recRent[0] * 12 - res?.tax[0]) * 0.3;
    // };
    const calc80C = () => {
      let total = 0;
      let d80Cgpf = res?.deductions?.["80C"]?.gpf || 0;
      let d80Chomeloan = res?.deductions?.["80C"]?.homeloan || 0;
      let d80Clic = res?.deductions?.["80C"]?.lic || 0;
      let d80Ctuition = res?.deductions?.["80C"]?.tuitionFee || 0;
      total = total + d80Cgpf + d80Chomeloan + d80Clic + d80Ctuition;
      console.log("80C: ", total);
      return total < 150000 ? total : 150000;
    };
    const calc80CCD = () => {
      let total = 0;
      let d80CCD1 = res?.deductions?.["80CCD"]?.["80CCD-1"] || 0;
      let d80CCD1B = res?.deductions?.["80CCD"]?.["80CCD-1B"] || 0;
      let d80CCD2 = res?.deductions?.["80CCD"]?.["80CCD-2"] || 0;
      total = d80CCD1B > d80CCD2 ? d80CCD1B : d80CCD2;
      console.log("80CCD", total);
      return total < 50000 ? total : 50000;
    };
    const calc80D = () => {
      let total = 0;
      let d80Dabove60 = res?.deductions?.["80D"]?.above60 || 0;
      let d80Dbelow60 = res?.deductions?.["80D"]?.below60 || 0;
      if (d80Dabove60) {
        total += d80Dabove60 < 25000 ? + d80Dabove60 : 25000;
      }
      if (d80Dbelow60) {
        total += d80Dbelow60 < 50000 ? + d80Dbelow60 : 50000;
      }
      console.log("80D: ", total);
      console.log("80DD: ", Number(res?.deductions?.["80DD"] || 0));
      return total;
    };
    const calc80DDB = () => {
      let total = 0;
      let d80DDBabove = res?.deductions?.above60 || 0;
      let d80DDBbelow = res?.deductions?.below60 || 0;
      if (d80DDBabove) {
        total = d80DDBabove < 100000 ? d80DDBabove : 100000;
      } else if (d80DDBbelow) {
        total = d80DDBbelow < 40000 ? d80DDBbelow : 40000;
      } else {
        total = 0;
      }
      console.log("80DDB: " + total);
      return total;
    };
    const calc80GG = () => {
      let total = 0;
      let d80GG = res?.deductions?.["80GG"] || 0;
      total = d80GG < 60000 ? d80GG : 60000;
      return total;
    };
    // const calc80TTA = () => {
    //   return +res?.$80tta[0] < 10000 ? +res?.$80tta[0] : 10000;
    // };
    const calcInterest = () => {
      let total = 0;
      let intDiv = res?.incomeFromOtherSources?.dividends;
      let intSaveAC = res?.incomeFromOtherSources?.interestIncome?.InterestOnSavingsAccount;
      let intTimeDep = res?.incomeFromOtherSources?.interestIncome?.InterestOnTimeDeposits;
      let intTime = res?.incomeFromOtherSources?.interestIncome?.otherInterest;
      total = intDiv + intSaveAC + intTimeDep + intTime;
      console.log("Interest Income: ", total);
      return total;
    };
    const calcIncome = () => {
      let total = 0;
      // let resLength = res?.incomeFromSalaries.length;
      // for (let i = 0; i < resLength; i++) {
      //   total += +res?.incomeFromSalaries?.[i]?.salary;
      // }
      total = res?.incomeFromSalaries[0]?.salary || 0;
      console.log("Gross: ", total);
      return total;
    };
    const calcIncomeOtherEmp = () => {
      let total = 0;
      let resLength = res?.incomeFromSalaries.length;
      for (let i = 1; i < resLength; i++) {
        total += +res?.incomeFromSalaries?.[i]?.salary;
      }
      console.log("Income Other Employee: ", total);
      return total;
    };
    const calcRent = () => {
      let total = 0;
      let rentLet = res?.incomeFromHouseProperty?.LetOut[0]?.grossAnnual;
      let rentSelf = res?.incomeFromHouseProperty?.SelfOccupied?.interestOnBorrowedCapital;
      if (rentLet) {
        let rentMunicipal = res?.incomeFromHouseProperty?.LetOut[0]?.municipalTaxes;
        let rentOneNet = rentLet - rentMunicipal;
        let rentNet = rentOneNet - (rentOneNet*0.30);
        let rentInt = res?.incomeFromHouseProperty?.LetOut[0]?.interestOnBorrowedCapital;
        total = rentNet - rentInt;
      } else if (rentSelf) {
        let selfInt = res?.incomeFromHouseProperty?.SelfOccupied[0]?.interestOnBorrowedCapital || 0
        let safeSelfInt = selfInt > 200000 ? 200000 : selfInt;
        total = 0 - safeSelfInt;
      }

      console.log("IncomeFromHouseProperty: ", total);
      return total;
    };
    const calc80U = () => {
      let total = 0;
      let d80Udis = res?.deductions?.["80U"]?.disability || 0;
      let d80Usevdis = res?.deductions?.["80U"]?.severeDisability || 0;
      if(d80Udis) {
        total = 75000;
      } else if(d80Usevdis) {
        total = 125000;
      } else {
        total = 0;
      }
      console.log("80U: ", total);
      return total;
    };
    const calc80TTA = () => {
      let total = 0;
      let d80TTA = res?.deductions?.["80TTA"] || 0;
      let dintSaveAC = res?.incomeFromOtherSources?.interestIncome?.InterestOnSavingsAccount;
      if (dintSaveAC) {
        total = Number(d80TTA < 10000 ? d80TTA : 10000);
      } else {
        total = 0;
      }
      console.log("80TTA: ", total);
      return total;
    };

    const data = {
      incomeData: [
        // {
        //   valid: res?.gross[0] ? true : false,
        //   name: "Total Salary",
        //   amt: Number(res?.gross[0]),
        //   id: 0,
        // },
        {
          valid: res?.incomeFromSalaries ? true : false,
          name: "Total Salary",
          amt: calcIncome(),
          id: 0,
        },
        // {
        //   valid: res?.recRent[0] ? true : false,
        //   name: "Rent Income",
        //   amt: Number(res?.recRent[0]),
        //   id: 1,
        // },
        {
          valid: res?.incomeFromHouseProperty ? true : false,
          name: "Rent Income",
          amt: calcRent(),
          id: 1,
        },
        {
          valid: res?.incomeFromOtherSources ? true : false,
          name: "Interest Income",
          // amt: Number(res?.interest[0]),
          amt: calcInterest(),
          id: 2,
        },
        // {
        //   valid: res?.interest[0] ? true : false,
        //   name: "Interest Income",
        //   // amt: Number(res?.interest[0]),
        //   amt: calcInterest(),
        //   id: 2,
        // },
        // {
        //   valid: res?.interest[0] ? true : false,
        //   name: "First Interest Income",
        //   amt: Number(res?.interest[0]),
        //   id: 3,
        // },
        {
          valid: res?.incomeFromSalaries ? true : false,
          name: "Income from Other Employer",
          amt: calcIncomeOtherEmp(),
          id: 4,
        },
      ],
      allowancesData: [
        {
          askUser: true,
          valid: res?.incomeFromSalaries ? true : false,
          name: "Cost Allowance",
          amt: Number(res?.incomeFromSalaries?.allowance?.hra || 0),
          id: 0,
        },
        {
          // askUser: true,
          valid: res?.incomeFromSalaries ? true : false,
          name: "Children Education Allowance",
          amt: Number(res?.incomeFromSalaries?.allowance?.edu || 0),
          id: 1,
        },
        {
          askUser: true,
          valid: res?.incomeFromSalaries ? true : false,
          name: "Rent Allowance",
          amt: Number(res?.incomeFromSalaries?.allowance?.rent || 0),
          id: 2,
        },
        {
          askUser: true,
          valid: res?.incomeFromSalaries ? true : false,
          name: "Travel Allowance",
          amt: Number(res?.incomeFromSalaries?.allowance?.travel || 0),
          id: 3,
        },
        {
          askUser: true,
          valid: res?.incomeFromSalaries ? true : false,
          name: "Other Allowances",
          amt: Number(res?.incomeFromSalaries?.allowance?.other || 0),
          id: 4,
        }
      ],
      deductionData: [
        {
          askUser: false,
          valid: true,
          name: "Standard Deduction",
          amt: 50000,
          id: 0,
        },
        {
          askUser: false,
          valid: true,
          name: "Professional Fees",
          amt: 2500,
          id: 1,
        },
        // {
        //   askUser: false,
        //   valid: res?.tax[0] ? true : false,
        //   name: "Municipal Tax",
        //   amt: Number(res?.tax[0]),
        //   id: 2,
        // },
        // {
        //   askUser: false,
        //   valid: res?.recRent[0] ? true : false,
        //   name: "Rent Deduction",
        //   amt: calc24(),
        //   id: 3,
        // },
        {
          askUser: true,
          valid: calc80C() ? true : false,
          name: t("80C, 80CCC & 80CCD(1)"),
          amt: calc80C(),
          id: 4,
          max: 150000,
        },
        {
          askUser: true,
          valid: res?.deductions ? true : false,
          name: t("NPS: 80CCD(1B), 80CCD(2)"),
          // amt: Number(res?.nps[0]) > 50000 ? 50000 : Number(res?.nps[0]),
          amt: calc80CCD(),
          id: 5,
          max: 50000,
        },
        {
          askUser: true,
          valid: calc80D() ? true : false,
          name: "80D",
          amt: calc80D(),
          id: 6,
          max: 75000,
        },
        {
          askUser: true,
          // eslint-disable-next-line no-extra-boolean-cast
          valid: res?.deductions?.["80DD"] ? true : false,
          name: "80DD",
          amt: Number(res?.deductions?.["80DD"] || 0),
          id: 7,
          max: 125000,
        },
        {
          askUser: true,
          valid: calc80DDB() ? true : false,
          name: "80DDB",
          amt: calc80DDB(),
          id: 8,
          max: 140000,
        },
        {
          askUser: true,
          valid: res?.deductions?.["80G"] ? true : false,
          name: "80G",
          amt: Number(res?.deductions?.["80G"] || 0),
          id: 9,
        },
        {
          askUser: true,
          valid: res?.deductions?.["80GG"] ? true : false,
          name: "80GG",
          amt: calc80GG(),
          id: 10,
        },
        // {
        //   askUser: true,
        //   valid: res?.$80tta[0] ? true : false,
        //   name: "80TTA",
        //   amt: calc80TTA(),
        //   id: 19,
        // },
        {
          askUser: true,
          valid: calc80U() ? true : false,
          name: "80U",
          amt: calc80U(),
          id: 18,
        },
        {
          askUser: true,
          valid: res?.deductions?.["80TTA"] ? true : false,
          name: "80TTA",
          amt: calc80TTA(),
          id: 17,
        },
      ],
      suggestions: res?.suggestions,
    };

    return data;
  } else {
    const data = {
      incomeData: [
        {
          valid: false,
          name: "Total Salary",
          amt: 0,
          id: 0,
        },
        {
          valid: false,
          name: "Rent Income",
          amt: 0,
          id: 1,
        },
        {
          valid: false,
          name: "Interest Income",
          amt: 0,
          id: 3,
        },
        {
          valid: false,
          name: "Income from Other Employer",
          amt: 0,
          id: 4,
        },
      ],
      allowancesData: [
        {
          askUser: true,
          valid: res?.costTourAllow ? true : false,
          name: "Cost Allowance",
          amt: Number(res?.costTourAllow),
          id: 0,
        },
        {
          askUser: true,
          valid: res?.childEduAllow ? true : false,
          name: "Children Education Allowance",
          amt: Number(res?.childEduAllow),
          id: 1,
        },
        {
          askUser: true,
          valid: res?.rentAllow ? true : false,
          name: "Rent Allowance",
          amt: Number(res?.rentAllow),
          id: 2,
        },
        {
          askUser: true,
          valid: res?.TravelAllow ? true : false,
          name: "Travel Allowance",
          amt: Number(res?.travelAllow),
          id: 3,
        },
        {
          askUser: true,
          valid: res?.otherAllow ? true : false,
          name: "Other Allowances",
          amt: Number(res?.otherAllow),
          id: 4,
        }
      ],
      deductionData: [
        {
          askUser: false,
          valid: true,
          name: "Standard Deduction",
          amt: 50000,
          id: 0,
        },
        {
          askUser: false,
          valid: true,
          name: "Professional Fees",
          amt: 2500,
          id: 1,
        },
        {
          askUser: true,
          valid: false,
          name: "Municipal Tax Paid",
          amt: 0,
          id: 2,
        },
        {
          askUser: true,
          valid: false,
          name: "24",
          amt: 0,
          id: 3,
        },
        { askUser: true, valid: false, name: "80C", amt: 0, id: 4 },
        {
          askUser: true,
          valid: false,
          name: "80CCD(1B)",
          amt: 0,
          id: 5,
        },
        { askUser: true, valid: false, name: "80D", amt: 0, id: 6 },
        {
          askUser: true,
          valid: false,
          name: "80DD(1B)",
          amt: 0,
          id: 7,
        },
        {
          askUser: true,
          valid: false,
          name: "80DDB",
          amt: 0,
          id: 8,
        },
        { askUser: true, valid: false, name: "80G", amt: res?.$80g, id: 10 },

        {
          askUser: true,
          valid: false,
          name: "80GG",
          amt: 0,
          id: 11,
        },

        // {
        //   askUser: true,
        //   valid: false,
        //   name: "80TTA",
        //   amt: 0,
        //   id: 19,
        // },

        {
          askUser: true,
          valid: false,
          name: "80U",
          amt: 0,
          id: 18,
        },


        {
          askUser: true,
          valid: false,
          name: "Interest on Home Loan",
          amt: 0,
          id: 17,
        },

      ],
      suggestions: [
        false /* 0 */,
        false /* 1 */,
        false /* 2 */,
        false /* 3 */,
        true /* 4 */,
        true /* 5 */,
        true /* 6 */,
        true /* 7 */,
        true /* 8 */,
        true /* 9 */,
        true /* 10 */,
      ],
    };

    return data;
  }
};




export const TotalTaxableIncome = (grossTotalIncome, Aggregate10, regime) => { 
  let taxableIncome = grossTotalIncome - Aggregate10;

  //console.log(totalIncome, totalDeduction);
  console.log("total taxable income", taxableIncome);
  // console.log(taxableIncome);
  if (regime == "New") {
    if (taxableIncome <= 300000) {
      return 0;
    } else if(taxableIncome >= 300001 && taxableIncome <= 700000) {
      return (0.05 * (taxableIncome - 300000)).toFixed(2);
    } else if(taxableIncome >= 700001 && taxableIncome <= 1000000) {
      return (
        20000 + 
        0.1 * (taxableIncome - 700000)
      ).toFixed(2);
    } else if(taxableIncome >= 1000000 && taxableIncome <= 1200000) {
      return (
        50000 + 
        0.15 * (taxableIncome - 1000000)
      ).toFixed(2);
    } else if(taxableIncome >= 1200000 && taxableIncome <= 1500000) {
      return (
        80000 + 
        0.2 * (taxableIncome - 1200000)
      ).toFixed(2);
    } else if(taxableIncome >= 1500000) {
      return (
        140000 + 
        0.3 * (taxableIncome - 1500000)
      ).toFixed(2);
    }
  } else {
    if (taxableIncome <= 250000) {
      // eslint-disable-next-line no-useless-concat
      return 0;
    } else if (taxableIncome >= 250001 && taxableIncome <= 500000) {
      return (0.05 * (taxableIncome - 250000)).toFixed(2);
    } else if (taxableIncome >= 500001 && taxableIncome < 1000000) {
      return (
        12500 +
        0.2 * (taxableIncome - 500000) 
      ).toFixed(2);
    } else if (taxableIncome >= 1000001) {
      return (
        112500 +
        0.3 * (taxableIncome - 1000000)
      ).toFixed(2);
    }
  }
};


export const TotalTaxPaid = (tax) => {
  let sum = 0;
  if (tax) {
    for (let i = 0; i < tax.length; i++) {
      sum = sum + tax[i].jan + tax[i].feb + tax[i].mar;
    }
  }
  return sum;
};

// userData: {
//   name: fData.name,
//   email: fData.email,
//   mobile: fData.phone,
//   pan: fData.pan,
//   question: fData.question,
//   role: fData.role,
//   accountNumber: 6666666666,
//   IFSC: "SBIN0000000",
//   aadhar: 666666666666,
//   pastITR: [
//     { year: "2020 - 21", link: "https://www.google.com" },
//     { year: "2021 - 22", link: "https://www.google.com" },
//     { year: "2022 - 23", link: "https://www.google.com" },
//   ],
// },
